import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import authServices from './auth.services';

export const AUTH_QUERY_ID = 'auth';

export function useAuthLogin() {
  return useMutation(authServices.login, {
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Autenticação',
        message: getErrorMessage(error),
      });
    },
  });
}

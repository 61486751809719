import axios from 'axios';

export const SERVICE_BASE_URL =
  process.env.REACT_APP_API_URL || 'https://fiducas-back-dev.azurewebsites.net';

const axiosInstance = axios.create({ baseURL: SERVICE_BASE_URL });

export const viaCepInstance = axios.create({
  baseURL: 'https://viacep.com.br/ws',
});

export default axiosInstance;

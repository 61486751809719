import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import { AuthData, AuthResponse } from './auth.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/auth`;

export default {
  async login(data?: AuthData) {
    const result = await axiosInstance.post<AuthResponse>(
      `${URL_CONTROLER}`,
      data,
    );

    return result.data;
  },
};

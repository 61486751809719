import { RequestPagination, ResponsePagination } from '@/core/types/services';
import { Product } from '../products/products.types';

export interface InventoryControl {
  id: string;
  product: Product;
  boxIdentifier: string;
  quantity: number;
  createdAt: string;
  validUntil: string;
  type: InventoryControlType;
}

export type InventoryControlType = 0 | 1 | 2;

export const InventoryControlTypes = ['Entrada', 'Saída', 'Vencido'];

export interface InventoryControlListParams extends RequestPagination {
  boxIdentifier?: string;
  startDate?: string;
  endDate?: string;
  isExpired?: boolean;
  type?: InventoryControlType;
}

export type InventoryControlListResponse = ResponsePagination<InventoryControl>;

export type InventoryControlFormValues = {
  productId: string;
  boxIdentifier: string;
  quantity: number;
  validUntil: string;
  type: string;
};

export type InventoryControlCreate = Omit<
  InventoryControlFormValues,
  'type'
> & { type: InventoryControlType };

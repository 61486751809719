export const FORM_VALIDATE = {
  required: 'Campo obrigatório!',
  email: 'E-mail inválido!',
  emailDiff: 'E-mails são diferentes',
  passwordDiff: 'Senhas são diferentes',
  min: (info: number | { min: number }) =>
    `Mínimo de ${typeof info === 'number' ? info : info.min} caracteres`,
  max: (info: number | { max: number }) =>
    `Máximo de ${typeof info === 'number' ? info : info.max} caracteres`,
  minCount: (info: number | { min: number }) =>
    `Mínimo de ${typeof info === 'number' ? info : info.min}`,
  minMax: ([min, max]: [number, number]) =>
    `Mínimo de ${min} e máximo de ${max}`,
  fileTooLarge: (fileSize: number) => `O arquivo é maior que ${fileSize}mb`,
};

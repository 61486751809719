import { useUserCreate } from '@/core/domains/users/users.hooks';
import { UserFormValues } from '@/core/domains/users/users.types';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { Button, Group, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RiSave2Line } from 'react-icons/ri';
import { userCreateFormValidate } from './formValidate';

type Props = { onCancel: VoidFunction };

const UserCreateForm: React.FC<Props> = ({ onCancel }) => {
  const form = useForm<UserFormValues>({
    validate: userCreateFormValidate,
  });

  const userCreate = useUserCreate();

  async function handleSubmit(values: UserFormValues) {
    try {
      await userCreate.mutateAsync(values);
      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          withAsterisk
          label="Primeiro nome"
          {...form.getInputProps('firstName')}
        />

        <TextInput
          withAsterisk
          label="Sobrenome"
          {...form.getInputProps('lastName')}
        />

        <TextInput
          withAsterisk
          label="E-mail"
          {...form.getInputProps('email')}
        />

        <PasswordInput
          withAsterisk
          label="Senha"
          {...form.getInputProps('password')}
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={userCreate.isLoading}
          >
            Salvar
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default UserCreateForm;

import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import usersServices from './users.services';
import { UserListParams } from './users.types';

export const USERS_QUERY_ID = 'users';
export const USER_ME_QUERY_ID = 'user/me';

export function useUserList(params?: UserListParams) {
  return useQuery([USERS_QUERY_ID, params], () => usersServices.list(params), {
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Listagem de usuários',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useUserMe() {
  return useQuery([USER_ME_QUERY_ID], usersServices.me, {
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Detalhe do usuário',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useUserInfo() {
  return useMutation(usersServices.detailInfo, {
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Detalhe do usuário',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useUserCreate() {
  const queryClient = useQueryClient();

  return useMutation(usersServices.create, {
    onSuccess(data) {
      queryClient.invalidateQueries([USERS_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Usuário cadastrado',
        message: `O usuário "${data.firstName} ${data.lastName}" foi cadastrado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação do usuário',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useUserEdit() {
  const queryClient = useQueryClient();

  return useMutation(usersServices.edit, {
    onSuccess(data) {
      queryClient.invalidateQueries([USERS_QUERY_ID]);
      queryClient.invalidateQueries([USER_ME_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Usuário editado',
        message: `O usuário "${data.firstName} ${data.lastName}" foi editado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição do usuário',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useUserRemove() {
  const queryClient = useQueryClient();

  return useMutation(usersServices.remove, {
    onSuccess() {
      queryClient.invalidateQueries([USERS_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Usuário removido',
        message: `O usuário foi removido com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição do usuário',
        message: getErrorMessage(error),
      });
    },
  });
}

import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const recoveryPasswordSendMailValidate = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .email(FORM_VALIDATE.email)
      .required(FORM_VALIDATE.required),
  }),
);

import { Table } from '@/components/_commons';
import { useBoxList, useBoxRemove } from '@/core/domains/boxes/boxes.hooks';
import { Box, BoxListParams } from '@/core/domains/boxes/boxes.types';
import { toPagination } from '@/core/utils/functions/pagination';
import { ActionIcon, Badge, Card, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedState } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { useEffect } from 'react';
import { RiDeleteBin2Line, RiEyeLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';

const BoxList: React.FC = () => {
  const modals = useModals();
  const filterForm = useForm<BoxListParams>();
  const [params, setParams] = useDebouncedState<BoxListParams>({}, 500);
  const navigate = useNavigate();

  const boxList = useBoxList(params);
  const boxRemove = useBoxRemove();

  useEffect(() => setParams(filterForm.values), [filterForm.values]);

  function handleRemove(box: Box) {
    modals.openConfirmModal({
      title: <Text weight="bold">Remover usuário</Text>,
      children: `Tem certeza que deseja remover o armário "${box.identifier}"?`,
      labels: {
        cancel: 'Cancelar',
        confirm: 'Remover',
      },
      closeOnConfirm: false,
      confirmProps: {
        color: 'red',
        loading: boxRemove.isLoading,
        onClick: async () => {
          await boxRemove.mutateAsync(box.identifier);
          closeAllModals();
        },
      },
    });
  }

  return (
    <Card withBorder>
      <Group>
        <TextInput
          placeholder="Pesquisar por modelo"
          mb="xl"
          {...filterForm.getInputProps('model')}
        />
      </Group>

      <Table
        columns={[
          {
            title: 'identificador',
            key: 'identifier',
          },

          {
            title: 'Modelo',
            key: 'model',
          },

          {
            title: 'Estabelecimento',
            key: 'company',
            render: (item) => item.company.name,
          },

          {
            title: 'Setores',
            key: 'sectors',
            render: (item) => (
              <>
                {item.sectors.slice(0, 2).map((sector) => (
                  <Badge key={sector.id} color="dark">
                    {sector.name}
                  </Badge>
                ))}
                {!!item.sectors.slice(2).length && (
                  <Badge color="dark">{`+${
                    item.sectors.slice(2).length
                  }`}</Badge>
                )}
              </>
            ),
          },

          {
            title: 'Ação',
            align: 'right',
            render: (item) => (
              <Group position="right">
                <ActionIcon
                  title="Visualizar"
                  onClick={() => navigate(item.identifier)}
                >
                  <RiEyeLine />
                </ActionIcon>

                <ActionIcon
                  title="Remover"
                  color="red.5"
                  onClick={() => handleRemove(item)}
                >
                  <RiDeleteBin2Line />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        data={boxList.data?.items}
        pagination={toPagination(boxList.data?.pagination)}
        loading={boxList.isLoading}
      />
    </Card>
  );
};

export default BoxList;

import { CompanyList } from '@/components/Companies';
import CompanyModalForm from '@/components/Companies/CompanyModalForm';
import { Button, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RiAddLine } from 'react-icons/ri';

const CompaniesPage: React.FC = () => {
  const [modalOpened, modalAction] = useDisclosure(false);

  return (
    <>
      <Group mb="xl" position="apart">
        <Title order={2}>Estabelecimentos</Title>

        <Button onClick={modalAction.open} leftIcon={<RiAddLine />}>
          Cadastrar estabelecimento
        </Button>
      </Group>

      <CompanyList />

      <CompanyModalForm opened={modalOpened} onClose={modalAction.close} />
    </>
  );
};

export default CompaniesPage;

import { User } from '@/core/domains/users/users.types';
import { Modal, Title } from '@mantine/core';
import UserCreateForm from '../UserCreateForm';
import UserEditForm from '../UserEditForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  user?: User;
};

const UserModalForm: React.FC<Props> = ({ opened, onClose, user }) => {
  const title = (
    <Title order={3}>{`${user ? 'Edição do' : 'Cadastro de'} usuário`}</Title>
  );

  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      {!!user && <UserEditForm onCancel={onClose} user={user} />}
      {!user && <UserCreateForm onCancel={onClose} />}
    </Modal>
  );
};

export default UserModalForm;

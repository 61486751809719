export interface AuthData {
  email: string;
  password: string;
}

export interface AuthResponse {
  token: string;
}

export type AuthRole = 'Admin' | 'Client';

export enum AuthRoleEnum {
  Admin = 'Admin',
  Cliente = 'Client',
}

export interface AuthDecoded {
  sub: string;
  name: string;
  emailaddress: string;
  role: AuthRole;
  exp: number;
  iss: string;
  aud: string;
}

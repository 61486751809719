import { useRecoveryPasswordSendMail } from '@/core/domains/recoveryPassword/recoveryPassword.hooks';
import { RecoveryPasswordSendMail } from '@/core/domains/recoveryPassword/recoveryPassword.types';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { Button, Modal, Group, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { recoveryPasswordSendMailValidate } from './formValitade';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
};

const RecoveryPasswordSendMailModalForm: React.FC<Props> = ({
  opened,
  onClose,
}) => {
  const form = useForm<RecoveryPasswordSendMail>({
    validate: recoveryPasswordSendMailValidate,
  });
  const recoverySendMail = useRecoveryPasswordSendMail();

  async function handleSubmit(values: RecoveryPasswordSendMail) {
    try {
      await recoverySendMail.mutateAsync(values);
      handleClose();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleClose() {
    onClose();
    form.reset();
  }

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={<Title order={3}>Recuperar senha</Title>}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Text mb="xl">
          Preencha seu endereço de e-mail para receber a mensagem de alteração
          de senha.
        </Text>

        <TextInput
          {...form.getInputProps('email')}
          withAsterisk
          label="E-mail"
          placeholder="nome@email.com"
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={recoverySendMail.isLoading} type="submit">
            Enviar
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default RecoveryPasswordSendMailModalForm;

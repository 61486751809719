import { useFileUpload } from '@/core/domains/files/files.hooks';
import {
  useSectorCreate,
  useSectorEdit,
} from '@/core/domains/sectors/sectors.hooks';
import {
  Sector,
  SectorCreateRequest,
  SectorFormValues,
} from '@/core/domains/sectors/sectors.types';
import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import {
  Button,
  Group,
  Stack,
  TextInput,
  Input,
  Text,
  useMantineTheme,
  Image,
  Anchor,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import {
  RiCloseLine,
  RiImageLine,
  RiSave2Line,
  RiUploadLine,
} from 'react-icons/ri';

import { sectorFormValidate } from './formValidate';

type Props = { onCancel: VoidFunction; sector?: Sector };

const fileErrors: Record<string, string> = {
  'file-too-large': FORM_VALIDATE.fileTooLarge(1),
  'file-invalid-type':
    'O arquivo deve ser do tipo: png, gif, jpeg, svg ou webp',
};

const SectorCreateForm: React.FC<Props> = ({ onCancel, sector }) => {
  const form = useForm<SectorFormValues>({
    validate: sectorFormValidate,
  });
  const theme = useMantineTheme();

  const sectorCreate = useSectorCreate();
  const sectorEdit = useSectorEdit();
  const fileUpload = useFileUpload();

  useEffect(() => {
    if (sector) {
      form.setValues({ name: sector.name, icon: sector.icon });
    }
  }, [sector]);

  async function handleSubmit({ icon, ...values }: SectorFormValues) {
    try {
      let obj = values as SectorCreateRequest;

      if (icon && typeof icon !== 'string') {
        const result = await fileUpload.mutateAsync([icon]);

        obj = { ...obj, icon: result.items[0].filePath };
      } else {
        obj = { ...obj, icon };
      }

      if (sector) {
        await sectorEdit.mutateAsync({ ...obj, id: sector.id });
      } else {
        await sectorCreate.mutateAsync(obj);
      }
      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  function iconPreview() {
    if (form.values.icon) {
      let imageSrc = '';

      if (typeof form.values.icon === 'string') {
        imageSrc = form.values.icon;
      } else {
        imageSrc = URL.createObjectURL(form.values.icon);
      }

      return (
        <Group position="center">
          <Image
            src={imageSrc}
            width={100}
            height={100}
            fit="contain"
            imageProps={{
              onLoad: () =>
                typeof form.values.icon !== 'string'
                  ? URL.revokeObjectURL(imageSrc)
                  : undefined,
            }}
            withPlaceholder
          />
        </Group>
      );
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          withAsterisk
          label="Nome do setor"
          {...form.getInputProps('name')}
        />

        <Input.Wrapper
          withAsterisk
          label="Ícone do setor"
          error={form.getInputProps('icon').error}
        >
          <div>
            <Anchor href="https://remixicon.com/" target="_blank">
              Ver galeria de icones
            </Anchor>
          </div>
          <Dropzone
            onDrop={([file]) => form.getInputProps('icon').onChange(file)}
            onReject={([file]) =>
              form.setFieldError('icon', fileErrors[file.errors[0].code])
            }
            multiple={false}
            accept={IMAGE_MIME_TYPE}
            maxSize={1024 ** 2}
          >
            <Group
              position="center"
              spacing="xl"
              style={{ pointerEvents: 'none' }}
            >
              <Dropzone.Accept>
                <RiUploadLine
                  size={50}
                  color={
                    theme.colors[theme.primaryColor][
                      theme.colorScheme === 'dark' ? 4 : 6
                    ]
                  }
                />
              </Dropzone.Accept>

              <Dropzone.Reject>
                <RiCloseLine
                  size={50}
                  color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                />
              </Dropzone.Reject>

              <Dropzone.Idle>
                <RiImageLine size={50} />
              </Dropzone.Idle>

              <Stack align="center">
                <Text size="lg" inline>
                  Arraste a imagem ou clique para selecionar
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  O Arquivo não pode ultrapassar mais de 1mb
                </Text>
              </Stack>
            </Group>
          </Dropzone>
        </Input.Wrapper>

        {iconPreview()}

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={
              sectorCreate.isLoading ||
              sectorEdit.isLoading ||
              fileUpload.isLoading
            }
          >
            {fileUpload.isLoading && 'Carregando imagem'}
            {(sectorCreate.isLoading || sectorEdit.isLoading) && 'Salvando'}
            {!fileUpload.isLoading &&
              !sectorCreate.isLoading &&
              !sectorEdit.isLoading &&
              'Salvar'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default SectorCreateForm;

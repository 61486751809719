import { CategorySelect } from '@/components/Categories';
import { useFileUpload } from '@/core/domains/files/files.hooks';
import {
  useProductCreate,
  useProductEdit,
} from '@/core/domains/products/products.hooks';
import {
  Product,
  ProductFormValues,
} from '@/core/domains/products/products.types';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import {
  Image,
  Button,
  Group,
  Input,
  NumberInput,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  Box,
  createStyles,
  ActionIcon,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import {
  RiBarcodeLine,
  RiDeleteBinLine,
  RiImage2Line,
  RiSave2Line,
} from 'react-icons/ri';
import { productFormValidate } from './formValidate';

type Props = { onCancel: VoidFunction; product?: Product };

function ImageUpload({
  url,
  onRemove,
}: {
  url: string;
  onRemove: VoidFunction;
}) {
  const { classes } = createStyles((_theme, _params, getRef) => ({
    box: {
      position: 'relative',

      [`&:hover .${getRef('stack')}`]: {
        display: 'flex',
      },
    },
    stack: {
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      ref: getRef('stack'),
    },
  }))();

  return (
    <Box className={classes.box}>
      <Image height="100%" radius="sm" withPlaceholder src={url} />
      <Stack align="center" justify="center" className={classes.stack}>
        <ActionIcon color="red" onClick={onRemove}>
          <RiDeleteBinLine size={24} />
        </ActionIcon>
      </Stack>
    </Box>
  );
}

const ProductForm: React.FC<Props> = ({ onCancel, product }) => {
  const form = useForm<ProductFormValues>({
    validate: productFormValidate,
  });

  const productCreate = useProductCreate();
  const productEdit = useProductEdit();
  const fileUpload = useFileUpload();

  useEffect(() => {
    if (product) {
      form.setValues({
        name: product.name,
        barCode: product.barCode,
        description: product.description,
        price: product.price,
        imageUris: product.imageUris,
        categoryId: product.category.id,
      });
    }
  }, [product]);

  function renderImage(image: File | string, index: number) {
    const onRemove = () =>
      form
        .getInputProps('imageUris')
        .onChange(
          form.values.imageUris.filter((_, imageIndex) => imageIndex !== index),
        );

    if (typeof image === 'string') {
      return <ImageUpload onRemove={onRemove} url={image} key={index} />;
    }

    const url = URL.createObjectURL(image);

    return <ImageUpload onRemove={onRemove} url={url} key={index} />;
  }

  async function handleSubmit({ imageUris, ...values }: ProductFormValues) {
    try {
      let images: string[] = imageUris.filter(
        (image) => typeof image === 'string',
      ) as string[];
      const imagesToUpload = imageUris.filter(
        (image) => typeof image !== 'string',
      ) as File[];

      if (imagesToUpload.length) {
        const result = await fileUpload.mutateAsync(imagesToUpload);

        if (result.items.length) {
          images = [...images, ...result.items.map((item) => item.filePath)];
        }
      }

      if (product) {
        await productEdit.mutateAsync({
          ...values,
          id: product.id,
          imageUris: images,
        });
      } else {
        await productCreate.mutateAsync({ ...values, imageUris: images });
      }

      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          withAsterisk
          label="Nome do produto"
          {...form.getInputProps('name')}
        />

        <TextInput
          withAsterisk
          label="Código do produto"
          rightSection={<RiBarcodeLine />}
          {...form.getInputProps('barCode')}
        />

        <Textarea
          withAsterisk
          label="Descrição do produto"
          minRows={3}
          {...form.getInputProps('description')}
        />

        <NumberInput
          withAsterisk
          label="Preço"
          {...form.getInputProps('price')}
          min={0}
        />

        <CategorySelect
          withAsterisk
          label="Categoria"
          placeholder="Selecione uma categoria"
          {...form.getInputProps('categoryId')}
        />

        <Input.Wrapper
          label="Imagens"
          withAsterisk
          description="Máximo de 5mb por arquivo"
          error={form.getInputProps('imageUris').error}
        >
          <SimpleGrid cols={3} my="sm">
            <Dropzone
              onDrop={(files) =>
                form
                  .getInputProps('imageUris')
                  .onChange([...(form.values?.imageUris || []), ...files])
              }
              accept={IMAGE_MIME_TYPE}
              maxSize={3 * 1024 ** 2}
            >
              <Stack align="center" justify="center" sx={{ height: '100%' }}>
                <RiImage2Line size={24} />
                <Text>Adicionar</Text>
              </Stack>
            </Dropzone>

            {form.values?.imageUris?.map(renderImage)}
          </SimpleGrid>
        </Input.Wrapper>

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={
              productCreate.isLoading ||
              productEdit.isLoading ||
              fileUpload.isLoading
            }
          >
            Salvar
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default ProductForm;

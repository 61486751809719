/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Stack,
  Pagination,
  ScrollArea,
  Table as MTable,
  Text,
  Box,
  Overlay,
} from '@mantine/core';
import { RiInboxLine } from 'react-icons/ri';
import { TableProps } from './Table.types';

function Table<D = object>(props: TableProps<D>) {
  const {
    data,
    columns,
    pagination,
    scrollArea,
    emptyMessage,
    loading,
    ...tableProps
  } = props;

  return (
    <Box sx={{ position: 'relative' }}>
      {loading && <Overlay opacity={0.8} />}
      <ScrollArea {...scrollArea}>
        <MTable highlightOnHover {...tableProps}>
          <thead>
            <tr>
              {columns?.map((column, index) => (
                <th
                  key={index}
                  title={column.title}
                  style={{ textAlign: column.align || 'left' }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!data?.length && (
              <tr>
                <td align="center" colSpan={columns?.length}>
                  <Stack align="center" m="xl">
                    <RiInboxLine size={32} />
                    <Text>{emptyMessage || 'Não há dados'}</Text>
                  </Stack>
                </td>
              </tr>
            )}

            {data?.map((item, itemIndex) => (
              <tr key={itemIndex}>
                {columns?.map((column, columnIndex) => {
                  const render = !!column.render
                    ? column.render
                    : () => <>{!!column.key ? item[column.key] : null}</>;

                  return (
                    <td
                      key={`${itemIndex}-${columnIndex}`}
                      align={column.align || 'left'}
                    >
                      {render(item, itemIndex)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </MTable>
        {!!pagination && (
          <Pagination mt="xl" position="center" {...pagination} />
        )}
      </ScrollArea>
    </Box>
  );
}

export default Table;

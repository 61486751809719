import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const inventoryControlFormValidate = yupResolver(
  Yup.object().shape({
    productId: Yup.string().required(FORM_VALIDATE.required),
    quantity: Yup.number()
      .min(0, FORM_VALIDATE.minCount)
      .required(FORM_VALIDATE.required),
    validUntil: Yup.string().required(FORM_VALIDATE.required),
    type: Yup.string().required(FORM_VALIDATE.required),
  }),
);

import HomePage from '@/pages/Public/Home';
import LoginPage from '@/pages/Public/Login';
import PrivacyPolicyPage from '@/pages/Public/PrivacyPolicy';
import ResetPasswordPage from '@/pages/Public/ResetPassword';
import { RouteProps } from 'react-router-context';
import { RouteParams } from '../types';

export default [
  { index: true, element: <HomePage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'reset-password/:code', element: <ResetPasswordPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
] as RouteProps<RouteParams>[];

import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  Product,
  ProductCreateRequest,
  ProductEditRequest,
  ProductListParams,
  ProductListResponse,
} from './products.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/products`;

export default {
  async list(params?: ProductListParams) {
    const result = await axiosInstance.get<ProductListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: ProductCreateRequest) {
    const result = await axiosInstance.post<Product>(`${URL_CONTROLER}`, data);

    return result.data;
  },

  async edit(data: ProductEditRequest) {
    const result = await axiosInstance.put<Product>(
      `${URL_CONTROLER}/${data.id}`,
      data,
    );

    return result.data;
  },

  async detail(productId: string) {
    const result = await axiosInstance.get<Product>(
      `${URL_CONTROLER}/${productId}`,
    );

    return result.data;
  },

  async remove(productId: string) {
    const result = await axiosInstance.delete<void>(
      `${URL_CONTROLER}/${productId}`,
    );

    return result.data;
  },
};

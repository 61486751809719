import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { profileFormValidate } from './formValidate';
import { UserEditRequest } from '@/core/domains/users/users.types';
import { useUserEdit, useUserMe } from '@/core/domains/users/users.hooks';
import { Button, Group, Skeleton, Stack, TextInput } from '@mantine/core';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { RiSave2Line } from 'react-icons/ri';
import { useAuth } from '@/core/contexts/auth';

type Props = {
  onCancel: VoidFunction;
};

const ProfileForm: React.FC<Props> = ({ onCancel }) => {
  const form = useForm<UserEditRequest>({ validate: profileFormValidate });
  const { data: user, isLoading } = useUserMe();
  const { setSession, session } = useAuth();

  const userEdit = useUserEdit();

  useEffect(() => {
    if (user) {
      form.setValues(user);
    }
  }, [user]);

  async function handleSubmit(values: UserEditRequest) {
    try {
      if (user) {
        await userEdit.mutateAsync({
          firstName: values.firstName,
          lastName: values.lastName,
          id: user?.id,
        });

        if (session?.user) {
          setSession({
            ...session,
            user: {
              ...session?.user,
              name: `${values.firstName} ${values.lastName}`,
            },
          });
        }
      }
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  return (
    <Skeleton visible={isLoading}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput
            label="Nome"
            withAsterisk
            {...form.getInputProps('firstName')}
          />

          <TextInput
            label="Sobrenome"
            withAsterisk
            {...form.getInputProps('lastName')}
          />

          <TextInput label="E-mail" {...form.getInputProps('email')} disabled />

          <Group position="right" mt="xl">
            <Button variant="default" onClick={handleCancel}>
              Cancelar
            </Button>

            <Button
              type="submit"
              leftIcon={<RiSave2Line />}
              loading={userEdit.isLoading}
            >
              Salvar
            </Button>
          </Group>
        </Stack>
      </form>
    </Skeleton>
  );
};

export default ProfileForm;

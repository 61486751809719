// import { BoxList, BoxModalForm } from '@/components/Boxes';
import { InventoryControlList } from '@/components/InventoryControls';
import { useBoxDetail } from '@/core/domains/boxes/boxes.hooks';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Group,
  SimpleGrid,
  Skeleton,
  Text,
  Title,
} from '@mantine/core';
import { RiArrowLeftSLine, RiEditLine, RiImageLine } from 'react-icons/ri';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router';
import { BoxModalForm } from '@/components/Boxes';

const BoxDetailPage: React.FC = () => {
  const [modalOpened, modalAction] = useDisclosure(false);
  const { boxIdentifier } = useParams<{ boxIdentifier: string }>();
  const navigate = useNavigate();

  const { data: box, isLoading } = useBoxDetail(boxIdentifier);

  return (
    <>
      <Group mb="xl" position="apart">
        <Skeleton visible={isLoading} sx={{ width: 'auto' }}>
          <Title order={2}>{`Armário: ${box?.identifier}`}</Title>
        </Skeleton>

        <Button
          onClick={() => navigate('/app/boxes')}
          leftIcon={<RiArrowLeftSLine />}
          variant="default"
        >
          Voltar
        </Button>
      </Group>

      <Skeleton visible={isLoading} mb="xl">
        <Card>
          <Group position="apart" mb="md">
            <Title order={3}>Dados gerais</Title>

            <Button
              variant="default"
              onClick={modalAction.open}
              leftIcon={<RiEditLine />}
            >
              Editar
            </Button>
          </Group>

          <SimpleGrid cols={4}>
            <Box>
              <Text weight="bold">Identificador</Text>
              <Text>{box?.identifier ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">Modelo</Text>
              <Text>{box?.model ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">Estabelecimento</Text>
              <Text>{box?.company.name ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">Setores</Text>
              <Group>
                {box?.sectors.map((sector) => (
                  <Badge key={sector.id} size="lg" color="dark">
                    <Group spacing="sm">
                      <Avatar src={sector.icon} size="sm" color="dark">
                        <RiImageLine size={20} color="grey" />
                      </Avatar>
                      <Text>{sector.name}</Text>
                    </Group>
                  </Badge>
                )) ?? '-'}
              </Group>
            </Box>
          </SimpleGrid>

          <Divider my="lg" />

          <Title mb="md" order={3}>
            Endereço
          </Title>

          <SimpleGrid cols={4}>
            <Box>
              <Text weight="bold">Logradouro</Text>
              <Text>{box?.address?.streetName ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">Número</Text>
              <Text>{box?.address?.streetNumber ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">Complemento</Text>
              <Text>{box?.address?.complement ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">Cidade</Text>
              <Text>{box?.address?.city ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">UF</Text>
              <Text>{box?.address?.uf ?? '-'}</Text>
            </Box>

            <Box>
              <Text weight="bold">CEP</Text>
              <Text>{box?.address?.cep ?? '-'}</Text>
            </Box>
          </SimpleGrid>
        </Card>
      </Skeleton>

      <InventoryControlList boxIdentifier={boxIdentifier} />

      <BoxModalForm
        opened={modalOpened}
        onClose={modalAction.close}
        box={box}
        key={box?.identifier}
      />
    </>
  );
};

export default BoxDetailPage;

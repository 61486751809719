import { UserList } from '@/components/Users';
import UserModalForm from '@/components/Users/UserModalForm';
import { Button, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router';

const UsersPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpened, modalAction] = useDisclosure(false);

  useEffect(() => {
    if (!location.pathname.includes('users')) {
      navigate('users');
    }
  }, [location.pathname]);

  return (
    <>
      <Group mb="xl" position="apart">
        <Title order={2}>Usuários</Title>

        <Button onClick={modalAction.open} leftIcon={<RiAddLine />}>
          Cadastrar usuário
        </Button>
      </Group>

      <UserList />

      <UserModalForm opened={modalOpened} onClose={modalAction.close} />
    </>
  );
};

export default UsersPage;

import { LoginForm } from '@/components/Login';
import { RecoveryPasswordSendMailModalForm } from '@/components/RecoveryPassword';
import { Box, Button, Card, Group, Image, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const LoginPage: React.FC = () => {
  const [recoveryOpened, recoveryAction] = useDisclosure(false);

  return (
    <>
      <Card withBorder>
        <Group position="center" mb="xl">
          <Image
            src="/images/fiducas-logo.png"
            alt="Logo fiducas"
            width={120}
          />
        </Group>
        <Text align="center" mb="xl">
          Acesso administrativo
        </Text>

        <LoginForm />

        <Box mt="xl" sx={{ textAlign: 'center' }}>
          <Button onClick={recoveryAction.open} variant="subtle">
            Recuperar senha
          </Button>
        </Box>
      </Card>

      <RecoveryPasswordSendMailModalForm
        opened={recoveryOpened}
        onClose={recoveryAction.close}
      />
    </>
  );
};

export default LoginPage;

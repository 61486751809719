import { AuthState, useAuth } from '@/core/contexts/auth';
import { AppShell, createStyles } from '@mantine/core';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { PrivateHeader, PrivateNav } from './components';

const LayoutPrivate: React.FC = () => {
  const { classes } = createStyles((theme) => ({
    main: {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  }))();
  const { state } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === AuthState.UNAUTHENTICATED) {
      navigate('/');
    }
  }, [state]);

  return (
    <AppShell
      header={<PrivateHeader />}
      navbar={<PrivateNav />}
      classNames={classes}
    >
      <Outlet />
    </AppShell>
  );
};

export default LayoutPrivate;

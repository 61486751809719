import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const boxFormValidate = yupResolver(
  Yup.object().shape({
    identifier: Yup.string().required(FORM_VALIDATE.required),
    model: Yup.string().required(FORM_VALIDATE.required),
    companyId: Yup.string().required(FORM_VALIDATE.required),
    sectorIds: Yup.array()
      .of(Yup.string())
      .required(FORM_VALIDATE.required)
      .min(1),
    address: Yup.object().when('hasSameCompanyAddress', {
      is: false,
      otherwise: Yup.object().shape({
        streetName: Yup.string(),
        streetNumber: Yup.string(),
        complement: Yup.string(),
        uf: Yup.string(),
        city: Yup.string(),
        cep: Yup.string(),
      }),
      then: Yup.object().shape({
        streetName: Yup.string().required(FORM_VALIDATE.required),
        streetNumber: Yup.string().required(FORM_VALIDATE.required),
        complement: Yup.string(),
        uf: Yup.string().required(FORM_VALIDATE.required),
        city: Yup.string().required(FORM_VALIDATE.required),
        cep: Yup.string().required(FORM_VALIDATE.required),
      }),
    }),
    hasSameCompanyAddress: Yup.boolean().required(FORM_VALIDATE.required),
  }),
);

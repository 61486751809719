import { CompanySelect } from '@/components/Companies';
import { SectorMultiSelect } from '@/components/Sectors';
import { MaskInput } from '@/components/_commons';
import {
  maskRemove,
  MASK_CEP,
} from '@/components/_commons/MaskInput/constants';
import { useBoxCreate, useBoxEdit } from '@/core/domains/boxes/boxes.hooks';
import { Box, BoxFormValues } from '@/core/domains/boxes/boxes.types';
import { useViaCep } from '@/core/domains/viaCep/viaCep.hooks';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import {
  Button,
  Checkbox,
  Group,
  SimpleGrid,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { RiSave2Line } from 'react-icons/ri';
import { boxFormValidate } from './formValidate';

type Props = { onCancel: VoidFunction; box?: Box };

const BoxCreateForm: React.FC<Props> = ({ onCancel, box }) => {
  const form = useForm<BoxFormValues>({
    validate: boxFormValidate,
    initialValues: { hasSameCompanyAddress: false } as BoxFormValues,
  });

  const boxCreate = useBoxCreate();
  const boxEdit = useBoxEdit();
  const viaCep = useViaCep();

  const hasCep = form.values?.address?.cep.length === 9;

  useEffect(() => {
    if (box) {
      const boxData = {
        ...box,
        sectorIds: box.sectors.map((sector) => sector.id),
        companyId: box.company.id,
      };

      if (boxData.hasSameCompanyAddress) {
        form.setValues({
          ...boxData,
          address: undefined,
        });
      } else if (!boxData.hasSameCompanyAddress && boxData.address) {
        form.setValues({
          ...boxData,
          address: undefined,
        });
      } else if (boxData.address) {
        form.setValues({
          ...boxData,
          address: undefined,
        });
      } else {
        form.setValues(boxData);
      }
    }
  }, [box]);

  useEffect(() => {
    if (form.values.hasSameCompanyAddress) {
      form.setFieldValue('address', undefined);
    } else {
      form.setFieldValue('address', {
        cep: '',
        city: '',
        complement: '',
        streetName: '',
        streetNumber: '',
        uf: '',
      });
    }
  }, [form.values.hasSameCompanyAddress]);

  useEffect(() => {
    getAddress();
  }, [form.values?.address?.cep]);

  async function getAddress() {
    if (form.values?.address?.cep && !form.values.hasSameCompanyAddress) {
      const cep = maskRemove(form.values?.address?.cep);

      if (cep.length !== 8) return;

      const address = await viaCep.mutateAsync(form.values.address.cep);

      form.setValues({
        ...form.values,
        address: {
          cep: address.cep,
          streetName: address.logradouro,
          complement: address.complemento,
          city: address.localidade,
          uf: address.uf,
          streetNumber: box?.address?.streetNumber || '',
        },
      });
    }
  }

  async function handleSubmit(values: BoxFormValues) {
    try {
      if (box) {
        await boxEdit.mutateAsync(
          values.hasSameCompanyAddress
            ? { ...values, address: undefined }
            : values,
        );
      } else {
        await boxCreate.mutateAsync(values);
      }
      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <SimpleGrid cols={2}>
          <TextInput
            withAsterisk
            label="Identificador do armário"
            {...form.getInputProps('identifier')}
            disabled={!!box}
          />

          <TextInput
            withAsterisk
            label="Modelo"
            {...form.getInputProps('model')}
          />

          <CompanySelect withAsterisk {...form.getInputProps('companyId')} />

          <SectorMultiSelect
            withAsterisk
            {...form.getInputProps('sectorIds')}
          />
        </SimpleGrid>

        <Checkbox
          label="Usar endereço do estabelecimento?"
          {...form.getInputProps('hasSameCompanyAddress', { type: 'checkbox' })}
        />

        {!form.values.hasSameCompanyAddress && (
          <SimpleGrid cols={2}>
            <MaskInput
              label="CEP"
              placeholder="00000-000"
              mask={MASK_CEP}
              {...form.getInputProps('address.cep')}
              withAsterisk
            />

            <TextInput
              label="Logradouro"
              placeholder="Rua, Avenida, etc"
              disabled={!hasCep}
              {...form.getInputProps('address.streetName')}
              withAsterisk
            />

            <TextInput
              label="Número"
              placeholder="000"
              disabled={!hasCep}
              {...form.getInputProps('address.streetNumber')}
              withAsterisk
            />

            <TextInput
              label="Complemento"
              disabled={!hasCep}
              {...form.getInputProps('address.complement')}
            />

            <TextInput
              label="Cidade"
              disabled={!hasCep}
              {...form.getInputProps('address.city')}
              withAsterisk
            />

            <TextInput
              label="Estado"
              placeholder="SP"
              maxLength={2}
              disabled={!hasCep}
              {...form.getInputProps('address.uf')}
              withAsterisk
            />
          </SimpleGrid>
        )}

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={boxCreate.isLoading || boxEdit.isLoading}
          >
            Salvar
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default BoxCreateForm;

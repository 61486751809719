import { MaskInput } from '@/components/_commons';
import {
  maskRemove,
  MASK_CEP,
} from '@/components/_commons/MaskInput/constants';
import {
  useCompanyCreate,
  useCompanyEdit,
} from '@/core/domains/companies/companies.hooks';
import {
  Company,
  CompanyFormValues,
} from '@/core/domains/companies/companies.types';
import { useViaCep } from '@/core/domains/viaCep/viaCep.hooks';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import {
  Box,
  Button,
  Group,
  Input,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { RiSave2Line } from 'react-icons/ri';
import { companyFormValidate } from './formValidate';

type Props = { onCancel: VoidFunction; company?: Company };

const CompanyCreateForm: React.FC<Props> = ({ onCancel, company }) => {
  const form = useForm<CompanyFormValues>({
    validate: companyFormValidate,
    initialValues: {
      address: {
        cep: '',
      },
    } as CompanyFormValues,
  });

  const companyCreate = useCompanyCreate();
  const companyEdit = useCompanyEdit();
  const viaCep = useViaCep();

  const hasCep = form.values.address.cep.length === 9;

  useEffect(() => {
    if (company) {
      form.setValues(company);
    }
  }, [company]);

  useEffect(() => {
    getAddress();
  }, [form.values?.address?.cep]);

  async function getAddress() {
    if (form.values?.address?.cep) {
      const cep = maskRemove(form.values?.address?.cep);

      if (cep.length !== 8) return;

      const address = await viaCep.mutateAsync(form.values.address.cep);

      form.setValues({
        ...form.values,
        address: {
          cep: address.cep,
          streetName: address.logradouro,
          complement: address.complemento,
          city: address.localidade,
          uf: address.uf,
          streetNumber: company?.address.streetNumber || '',
        },
      });
    }
  }

  async function handleSubmit(values: CompanyFormValues) {
    try {
      if (company) {
        await companyEdit.mutateAsync({ ...values, id: company.id });
      } else {
        await companyCreate.mutateAsync(values);
      }
      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  function renderInputTime(day: string) {
    type BusinnessHours = Record<string, [string, string]>;

    let businnessHours = {} as BusinnessHours;

    try {
      businnessHours = JSON.parse(
        form.values.businnessHours || '{}',
      ) as BusinnessHours;
    } catch {
      form.setFieldError('businnessHours', 'Dados do horário inválido');
    }

    const startTime = businnessHours[day] ? businnessHours[day][0] : '';
    const endTime = businnessHours[day] ? businnessHours[day][1] : '';

    return (
      <Group grow>
        <MaskInput
          label="Entrada"
          mask="99:99"
          placeholder="00:00"
          value={startTime}
          onChange={({ target }) => {
            form.setFieldValue(
              'businnessHours',
              JSON.stringify({
                ...businnessHours,
                [day]: [target.value, endTime],
              }),
            );
          }}
        />

        <MaskInput
          label="Saída"
          mask="99:99"
          placeholder="00:00"
          value={endTime}
          onChange={({ target }) => {
            form.setFieldValue(
              'businnessHours',
              JSON.stringify({
                ...businnessHours,
                [day]: [startTime, target.value],
              }),
            );
          }}
        />
      </Group>
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Group grow>
          <TextInput
            withAsterisk
            label="Nome da empresa"
            {...form.getInputProps('name')}
          />

          <TextInput
            withAsterisk
            label="CNPJ"
            {...form.getInputProps('cnpj')}
          />
        </Group>

        <Textarea
          withAsterisk
          label="Contato"
          minRows={4}
          placeholder={`Nome\nTelefone\nE-mail
            `}
          {...form.getInputProps('contact')}
        />

        <Box>
          <Text weight="bold">Endereço</Text>

          <SimpleGrid cols={2}>
            <MaskInput
              label="CEP"
              placeholder="00000-000"
              mask={MASK_CEP}
              withAsterisk
              {...form.getInputProps('address.cep')}
            />

            <TextInput
              withAsterisk
              label="Logradouro"
              placeholder="Rua, Avenida, etc"
              disabled={!hasCep}
              {...form.getInputProps('address.streetName')}
            />

            <TextInput
              withAsterisk
              label="Número"
              placeholder="000"
              disabled={!hasCep}
              {...form.getInputProps('address.streetNumber')}
            />

            <TextInput
              label="Complemento"
              disabled={!hasCep}
              {...form.getInputProps('address.complement')}
            />

            <TextInput
              withAsterisk
              label="Cidade"
              disabled={!hasCep}
              {...form.getInputProps('address.city')}
            />

            <TextInput
              withAsterisk
              label="Estado"
              placeholder="SP"
              maxLength={2}
              disabled={!hasCep}
              {...form.getInputProps('address.uf')}
            />
          </SimpleGrid>

          <Input.Wrapper error={form.getInputProps('businnessHours').error}>
            <Text mt="xl" weight="bold">
              Horário de funcionamento
            </Text>
          </Input.Wrapper>

          <Stack>
            <Box>
              <Text>Segunda</Text>
              {renderInputTime('Segunda')}
            </Box>
            <Box>
              <Text>Terça</Text>
              {renderInputTime('Terça')}
            </Box>
            <Box>
              <Text>Quarta</Text>
              {renderInputTime('Quarta')}
            </Box>
            <Box>
              <Text>Quinta</Text>
              {renderInputTime('Quinta')}
            </Box>
            <Box>
              <Text>Sexta</Text>
              {renderInputTime('Sexta')}
            </Box>
            <Box>
              <Text>Sábado</Text>
              {renderInputTime('Sábado')}
            </Box>
            <Box>
              <Text>Domingo</Text>
              {renderInputTime('Domingo')}
            </Box>
          </Stack>
        </Box>

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={companyCreate.isLoading || companyEdit.isLoading}
          >
            Salvar
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CompanyCreateForm;

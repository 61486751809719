import { RouteParams } from '@/core/routes/types';
import { Navbar, UnstyledButton, Group, Text } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router';
import { useRoutes } from 'react-router-context';

const PrivateNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [routes] = useRoutes<RouteParams>(['app']);

  return (
    <Navbar width={{ base: 250 }}>
      {routes?.children
        ?.filter(
          ({ params }) =>
            (typeof params?.hidden === 'boolean' && params.hidden !== true) ||
            typeof params?.hidden === 'undefined',
        )
        .map((route) => {
          const path = `/app/${route.path}`;
          return (
            <UnstyledButton
              key={path}
              onClick={() => navigate(path)}
              p="md"
              sx={(theme) => ({
                color:
                  location.pathname === path
                    ? theme.colors.primary[6]
                    : theme.colorScheme === 'dark'
                    ? theme.white
                    : theme.black,
                position: 'relative',

                '&::after': {
                  content: "''",
                  width: 3,
                  height: '50%',
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor:
                    location.pathname === path
                      ? theme.colors.primary[6]
                      : 'transparent',
                },
              })}
            >
              <Group>
                {route.params?.icon}
                <Text>{route.params?.title}</Text>
              </Group>
            </UnstyledButton>
          );
        })}
    </Navbar>
  );
};

export default PrivateNav;

import { Box } from '@/core/domains/boxes/boxes.types';
import { Modal, Title } from '@mantine/core';
import CategoryForm from '../BoxForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  box?: Box;
};

const CategoryModalForm: React.FC<Props> = ({ opened, onClose, box }) => {
  const title = (
    <Title order={3}>{`${box ? 'Edição de' : 'Cadastro de'} armário`}</Title>
  );

  return (
    <Modal opened={opened} onClose={onClose} title={title} size="lg">
      <CategoryForm onCancel={onClose} box={box} />
    </Modal>
  );
};

export default CategoryModalForm;

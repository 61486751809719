import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import categoriesServices from './files.services';

export function useFileUpload() {
  return useMutation(categoriesServices.upload, {
    onSuccess() {
      showNotification({
        color: 'green',
        title: 'Upload concluido',
        message: `O upload dos arquivos foram feitos com sucesso!`,
      });
    },

    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Upload dos arquivos',
        message: getErrorMessage(error),
      });
    },
  });
}

import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import boxesServices from './boxes.services';
import { BoxListParams } from './boxes.types';

export const BOX_QUERY_ID = 'boxes';
export const BOX_DETAIL_QUERY_ID = 'box';

export function useBoxList(params?: BoxListParams) {
  return useQuery([BOX_QUERY_ID, params], () => boxesServices.list(params), {
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Listagem de armários',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useBoxDetail(boxIdentifier?: string) {
  return useQuery(
    [BOX_DETAIL_QUERY_ID, boxIdentifier],
    () => (boxIdentifier ? boxesServices.detail(boxIdentifier) : undefined),
    {
      enabled: !!boxIdentifier,
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Detalhe do armário',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

export function useBoxCreate() {
  const queryClient = useQueryClient();

  return useMutation(boxesServices.create, {
    onSuccess() {
      queryClient.invalidateQueries([BOX_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Armario cadastrado',
        message: `O armário foi cadastrado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação da armário',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useBoxEdit() {
  const queryClient = useQueryClient();

  return useMutation(boxesServices.edit, {
    onSuccess() {
      queryClient.invalidateQueries([BOX_QUERY_ID]);
      queryClient.invalidateQueries([BOX_DETAIL_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Armario editado',
        message: `O armário foi editado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição da armário',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useBoxRemove() {
  const queryClient = useQueryClient();

  return useMutation(boxesServices.remove, {
    onSuccess() {
      queryClient.invalidateQueries([BOX_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Armario removido',
        message: `O armário foi removido com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Remoção da armário',
        message: getErrorMessage(error),
      });
    },
  });
}

import { CategoryList } from '@/components/Categories';
import CategoryModalForm from '@/components/Categories/CategoryModalForm';
import { Button, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RiAddLine } from 'react-icons/ri';

const CategoriesPage: React.FC = () => {
  const [modalOpened, modalAction] = useDisclosure(false);

  return (
    <>
      <Group mb="xl" position="apart">
        <Title order={2}>Categorias</Title>

        <Button onClick={modalAction.open} leftIcon={<RiAddLine />}>
          Cadastrar categoria
        </Button>
      </Group>

      <CategoryList />

      <CategoryModalForm opened={modalOpened} onClose={modalAction.close} />
    </>
  );
};

export default CategoriesPage;

import { useCompanyList } from '@/core/domains/companies/companies.hooks';
import { Select, SelectProps } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';

const CompanySelect: React.FC<SelectProps> = ({
  label = 'Estabelecimento',
  placeholder = 'Selecionar estabelecimento',
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebouncedValue(search, 500);

  const companyList = useCompanyList({ search: searchDebounced });

  return (
    <Select
      {...props}
      data={
        companyList.data?.items.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      }
      label={label}
      placeholder={placeholder}
      searchable
      clearable
      onSearchChange={setSearch}
      icon={<RiSearch2Line size={18} />}
    />
  );
};

export default CompanySelect;

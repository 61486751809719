import { MantineConfigProvider } from '@/core/configs/mantine';
import queryClient, { QueryClientProvider } from '@/core/configs/react-query';
import {
  AuthProvider,
  AuthState,
  AuthStateTypes,
  Session,
} from '@/core/contexts/auth';
import routes from '@/core/routes';
import { IconContext } from 'react-icons';
import { ReactRouterContext as RouterProvider } from 'react-router-context';
import axiosInstance from './core/configs/axios';
import AxiosProvider from './core/configs/axios/Axios.provider';

function App() {
  function watchSession(session: Session | null, state: AuthStateTypes) {
    if (state === AuthState.AUTHENTICATED && session?.tokens.accessToken) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `${session.tokens.tokenType} ${session.tokens.accessToken}`;
    }
  }

  return (
    <IconContext.Provider value={{}}>
      <AuthProvider watchSession={watchSession}>
        <AxiosProvider axiosClient={axiosInstance}>
          <MantineConfigProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider routes={routes} />
            </QueryClientProvider>
          </MantineConfigProvider>
        </AxiosProvider>
      </AuthProvider>
    </IconContext.Provider>
  );
}

export default App;

import { viaCepInstance } from '@/core/configs/axios';
import { ViaCepData } from './viaCep.types';

export default {
  async addressByCep(cep: string) {
    const result = await viaCepInstance.get<ViaCepData>(`/${cep}/json`);

    return result.data;
  },
};

import { PaginationData } from '@/core/types/services';
import { PaginationProps } from '@mantine/core';

export function toPagination(pagination?: PaginationData) {
  if (!pagination) return undefined;

  return {
    page: pagination.page,
    total: pagination.pageCount,
  } as PaginationProps;
}

export function toPaginationData(pagination?: PaginationProps) {
  if (!pagination) return undefined;

  return {
    page: pagination.page || 1,
    pageCount: pagination.total,
  } as PaginationData;
}

import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const profileFormValidate = yupResolver(
  Yup.object().shape({
    firstName: Yup.string().required(FORM_VALIDATE.required),
    lastName: Yup.string().required(FORM_VALIDATE.required),
  }),
);

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosErrorResponse } from '@/core/types/services';
import { UseFormReturnType } from '@mantine/form';
import getFieldPath from './getFieldPath';
// import getFieldPath from './getFieldPath';

export default function formSetFieldsErrors(
  form: UseFormReturnType<any>,
  err: unknown,
  parent?: { value: any; path: (string | number)[] },
) {
  const error = err as AxiosErrorResponse;

  if (
    error.isAxiosError &&
    error.response &&
    error.response.data?.fieldErrors
  ) {
    const { data } = error.response;

    let fieldErros = {};

    Object.keys(data.fieldErrors).forEach((errorKey) => {
      let fieldKey = getFieldPath(errorKey, form.values).join('.');

      if (parent) {
        fieldKey = [
          ...parent.path,
          ...getFieldPath(errorKey, parent.value),
        ].join('.');
      }

      fieldErros = {
        ...fieldErros,
        [fieldKey]: data.fieldErrors[errorKey][0],
      };
    });

    form.setErrors(fieldErros);
  }
}

import { MantineThemeOverride } from '@mantine/core';

export default {
  colorScheme: 'dark',

  colors: {
    primary: [
      '#E6FCF5',
      '#E1FBE8',
      '#C4F8D8',
      '#A1ECC5',
      '#83D9B5',
      '#5BC1A0',
      '#42A58F',
      '#2D8A7F',
      '#1D6F6D',
      '#11575C',
    ],
  },

  primaryColor: 'primary',
  primaryShade: { light: 5, dark: 5 },

  defaultRadius: 5,
  spacing: {
    xs: 10,
    sm: 12,
    md: 16,
    lg: 20,
    xl: 24,
  },

  components: {
    Card: {
      defaultProps: {
        p: 24,
      },
    },
  },
} as MantineThemeOverride;

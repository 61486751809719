import { useRecoveryPasswordValidateCode } from '@/core/domains/recoveryPassword/recoveryPassword.hooks';
import { Button, Card, Group, Image, Loader, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { RecoveryPasswordForm } from '@/components/RecoveryPassword';

const ResetPasswordPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();

  const [status, setStatus] = useState<'VALID' | 'INVALID'>();

  const { error, isLoading } = useRecoveryPasswordValidateCode(code);

  useEffect(() => {
    if (error) {
      setStatus('INVALID');
    } else {
      setStatus('VALID');
    }
  }, [error]);

  return (
    <>
      <Card withBorder sx={{ minWidth: 300 }}>
        <Group position="center" mb="xl">
          <Image
            src="/images/fiducas-logo.png"
            alt="Logo fiducas"
            width={120}
          />
        </Group>
        <Text align="center" mb="xl">
          Recuperação de senha
        </Text>

        {isLoading && !error && (
          <Group position="center">
            <Loader size="sm" />
            <Text>Verificando código...</Text>
          </Group>
        )}

        {!isLoading && status === 'INVALID' && (
          <Stack align="center">
            <Text color="dimmed">Código informado inválido</Text>
            <Button component="a" href="/login" variant="default">
              Voltar
            </Button>
          </Stack>
        )}

        {!isLoading && status === 'VALID' && (
          <RecoveryPasswordForm code={code} />
        )}
      </Card>
    </>
  );
};

export default ResetPasswordPage;

import { ProductSelect } from '@/components/Products';
import { useInventoryControlCreate } from '@/core/domains/inventoryControls/inventoryControls.hooks';
import {
  InventoryControlFormValues,
  InventoryControlType,
} from '@/core/domains/inventoryControls/inventoryControls.types';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { Button, Group, Modal, NumberInput, Stack, Title } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { RiCalendarLine, RiSave2Line } from 'react-icons/ri';
import TypeSelect from '../TypeSelect';
import { inventoryControlFormValidate } from './formValidate';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  boxIdentifier?: string;
};

const InventoryControlModalForm: React.FC<Props> = ({
  opened,
  onClose,
  boxIdentifier,
}) => {
  const form = useForm<InventoryControlFormValues>({
    validate: inventoryControlFormValidate,
  });

  const inventoryControlCreate = useInventoryControlCreate();

  async function handleSubmit(values: InventoryControlFormValues) {
    try {
      console.log(boxIdentifier);
      if (boxIdentifier) {
        await inventoryControlCreate.mutateAsync({
          ...values,
          type: Number(values.type) as InventoryControlType,
          boxIdentifier,
        });

        handleCancel();
      }
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onClose();
    form.reset();
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Controle de inventário</Title>}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <ProductSelect
            withAsterisk
            label="Produto"
            placeholder="Selecione o produto"
            {...form.getInputProps('productId')}
          />

          <NumberInput
            withAsterisk
            label="Quantidade"
            {...form.getInputProps('quantity')}
          />

          <DatePicker
            withAsterisk
            label="Válidade do produto"
            icon={<RiCalendarLine />}
            placeholder="00/00/0000"
            inputFormat="DD/MM/YYYY"
            {...form.getInputProps('validUntil')}
          />

          <TypeSelect
            withAsterisk
            isFilter={false}
            {...form.getInputProps('type')}
          />

          <Group position="right" mt="xl">
            <Button variant="default" onClick={handleCancel}>
              Cancelar
            </Button>

            <Button
              type="submit"
              leftIcon={<RiSave2Line />}
              loading={inventoryControlCreate.isLoading}
            >
              Salvar
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default InventoryControlModalForm;

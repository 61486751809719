import { Table } from '@/components/_commons';
import { useUserList, useUserRemove } from '@/core/domains/users/users.hooks';
import { User, UserListParams } from '@/core/domains/users/users.types';
import { toPagination } from '@/core/utils/functions/pagination';
import { ActionIcon, Card, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { RiDeleteBin2Line, RiEdit2Line } from 'react-icons/ri';
import UserModalForm from '../UserModalForm';

const UserList: React.FC = () => {
  const modals = useModals();
  const filterForm = useForm<UserListParams>();
  const [params, setParams] = useDebouncedState<UserListParams>({}, 500);
  const [userSelected, setUserSelected] = useState<User>();
  const [modalOpened, modalAction] = useDisclosure(false, {
    onClose: () => setUserSelected(undefined),
  });

  const userList = useUserList(params);
  const userRemove = useUserRemove();

  useEffect(() => setParams(filterForm.values), [filterForm.values]);

  function handleRemove(user: User) {
    modals.openConfirmModal({
      title: <Text weight="bold">Remover usuário</Text>,
      children: `Tem certeza que deseja remover o usuário "${user.firstName}"?`,
      labels: {
        cancel: 'Cancelar',
        confirm: 'Remover',
      },
      closeOnConfirm: false,
      confirmProps: {
        color: 'red',
        loading: userRemove.isLoading,
        onClick: async () => {
          await userRemove.mutateAsync(user.id);
          closeAllModals();
        },
      },
    });
  }

  function handleEdit(user: User) {
    setUserSelected(user);
    modalAction.open();
  }

  return (
    <Card withBorder>
      <Group>
        <TextInput
          placeholder="Pesquisar usuários"
          mb="xl"
          {...filterForm.getInputProps('search')}
        />
      </Group>

      <Table
        columns={[
          {
            title: 'Nome completo',
            render: (item) => `${item.firstName} ${item.lastName}`,
          },
          {
            title: 'E-mail',
            key: 'email',
          },
          {
            title: 'Ação',
            align: 'right',
            render: (item) => (
              <Group position="right">
                <ActionIcon title="Editar" onClick={() => handleEdit(item)}>
                  <RiEdit2Line />
                </ActionIcon>

                <ActionIcon
                  title="Remover"
                  color="red.5"
                  onClick={() => handleRemove(item)}
                >
                  <RiDeleteBin2Line />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        data={userList.data?.items}
        pagination={toPagination(userList.data?.pagination)}
        loading={userList.isLoading}
      />

      <UserModalForm
        opened={modalOpened}
        onClose={modalAction.close}
        user={userSelected}
        key={userSelected?.id}
      />
    </Card>
  );
};

export default UserList;

import { useUserEdit } from '@/core/domains/users/users.hooks';
import { User, UserEditRequest } from '@/core/domains/users/users.types';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { RiSave2Line } from 'react-icons/ri';
import { userEditFormValidate } from './formValidate';

type Props = { user: User; onCancel: VoidFunction };

const UserEditForm: React.FC<Props> = ({ user, onCancel }) => {
  const form = useForm<UserEditRequest>({
    validate: userEditFormValidate,
  });

  const userEdit = useUserEdit();

  useEffect(() => form.setValues(user), [user]);

  async function handleSubmit(values: UserEditRequest) {
    try {
      await userEdit.mutateAsync({
        firstName: values.firstName,
        lastName: values.lastName,
        id: user.id,
      });
      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          withAsterisk
          label="Primeiro nome"
          {...form.getInputProps('firstName')}
        />

        <TextInput
          withAsterisk
          label="Sobrenome"
          {...form.getInputProps('lastName')}
        />

        <TextInput withAsterisk label="E-mail" disabled value={user.email} />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={userEdit.isLoading}
          >
            Salvar
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default UserEditForm;

import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import inventoryControlsServices from './inventoryControls.services';
import { InventoryControlListParams } from './inventoryControls.types';

export const INVENTORY_CONTROLS_QUERY_ID = 'inventoryControls';

export function useInventoryControlList(params?: InventoryControlListParams) {
  return useQuery(
    [INVENTORY_CONTROLS_QUERY_ID, params],
    () => inventoryControlsServices.list(params),
    {
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Listagem de controle do inventário',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

export function useInventoryControlCreate() {
  const queryClient = useQueryClient();

  return useMutation(inventoryControlsServices.create, {
    onSuccess() {
      queryClient.invalidateQueries([INVENTORY_CONTROLS_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Controle do inventário cadastrada',
        message: `O controle do inventário  foi cadastrada com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação da categoria',
        message: getErrorMessage(error),
      });
    },
  });
}

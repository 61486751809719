import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import authServices from './recoveryPassword.services';

export const RECOVERY_PASSWORD_QUERY_ID = 'recoveryPassword';

export function useRecoveryPasswordSendMail() {
  return useMutation(authServices.sendMail, {
    onSuccess() {
      showNotification({
        color: 'green',
        title: 'Recuperação de senha',
        message: 'Um link de confirmação foi enviado para o seu e-mail.',
      });
    },

    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Envio de email',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useRecoveryPasswordChange() {
  return useMutation(authServices.changePassword, {
    onSuccess() {
      showNotification({
        color: 'green',
        title: 'Senha alterada',
        message: 'Sua senha foi alterada com sucesso!',
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Alteração de senha',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useRecoveryPasswordValidateCode(code?: string) {
  return useQuery(
    ['validateCode', code],
    () => (code ? authServices.validateCode(code) : null),
    {
      enabled: !!code,
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Alteração de senha',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

import { Category } from '@/core/domains/categories/categories.types';
import { Modal, Title } from '@mantine/core';
import CategoryForm from '../CategoryForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  category?: Category;
};

const CategoryModalForm: React.FC<Props> = ({ opened, onClose, category }) => {
  const title = (
    <Title order={3}>{`${
      category ? 'Edição de' : 'Cadastro de'
    } categoria`}</Title>
  );

  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      <CategoryForm onCancel={onClose} category={category} />
    </Modal>
  );
};

export default CategoryModalForm;

import { InputBase, InputBaseProps } from '@mantine/core';
import InputMask, { Props } from 'react-input-mask';

const MaskInput: React.FC<InputBaseProps & Props> = ({
  mask,
  maskPlaceholder,
  alwaysShowMask,
  inputRef,
  ...props
}) => {
  return (
    <InputBase
      component={InputMask}
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      alwaysShowMask={alwaysShowMask}
      inputRef={inputRef}
      {...props}
    />
  );
};

export default MaskInput;

import { useCategoryList } from '@/core/domains/categories/categories.hooks';
import { Select, SelectProps } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';

const CategorySelect: React.FC<SelectProps> = ({
  label = 'Categoria',
  placeholder = 'Selecionar categoria',
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebouncedValue(search, 500);

  const categoryList = useCategoryList({ search: searchDebounced });

  return (
    <Select
      {...props}
      data={
        categoryList.data?.items.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      }
      label={label}
      placeholder={placeholder}
      searchable
      clearable
      onSearchChange={setSearch}
      icon={<RiSearch2Line size={18} />}
    />
  );
};

export default CategorySelect;

import { Table } from '@/components/_commons';
import {
  useCompanyList,
  useCompanyRemove,
} from '@/core/domains/companies/companies.hooks';
import {
  Company,
  CompanyListParams,
} from '@/core/domains/companies/companies.types';
import { toPagination } from '@/core/utils/functions/pagination';
import { ActionIcon, Card, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { RiDeleteBin2Line, RiEdit2Line } from 'react-icons/ri';
import CompanyModalForm from '../CompanyModalForm';

const CompanyList: React.FC = () => {
  const modals = useModals();
  const filterForm = useForm<CompanyListParams>();
  const [params, setParams] = useDebouncedState<CompanyListParams>({}, 500);
  const [CompanySelected, setCompanySelected] = useState<Company>();
  const [modalOpened, modalAction] = useDisclosure(false, {
    onClose: () => setCompanySelected(undefined),
  });

  const companyList = useCompanyList(params);
  const companyRemove = useCompanyRemove();

  useEffect(() => setParams(filterForm.values), [filterForm.values]);

  function handleRemove(company: Company) {
    modals.openConfirmModal({
      title: <Text weight="bold">Remover usuário</Text>,
      children: `Tem certeza que deseja remover o estabeleciamento "${company.name}"?`,
      labels: {
        cancel: 'Cancelar',
        confirm: 'Remover',
      },
      closeOnConfirm: false,
      confirmProps: {
        color: 'red',
        loading: companyRemove.isLoading,
        onClick: async () => {
          await companyRemove.mutateAsync(company.id);
          closeAllModals();
        },
      },
    });
  }

  function handleEdit(company: Company) {
    setCompanySelected(company);
    modalAction.open();
  }

  return (
    <Card withBorder>
      <Group>
        <TextInput
          placeholder="Pesquisar estabelecimento"
          mb="xl"
          {...filterForm.getInputProps('search')}
        />

        <TextInput
          placeholder="CNPJ"
          mb="xl"
          {...filterForm.getInputProps('cnpj')}
        />
      </Group>

      <Table
        columns={[
          {
            title: 'Estabelecimento',
            key: 'name',
          },

          {
            title: 'CNPJ',
            key: 'cnpj',
          },

          {
            title: 'Qtd. de caixas',
            key: 'boxCount',
            align: 'right',
          },

          {
            title: 'Ação',
            align: 'right',
            render: (item) => (
              <Group position="right">
                <ActionIcon title="Editar" onClick={() => handleEdit(item)}>
                  <RiEdit2Line />
                </ActionIcon>

                <ActionIcon
                  title="Remover"
                  color="red.5"
                  onClick={() => handleRemove(item)}
                >
                  <RiDeleteBin2Line />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        data={companyList.data?.items}
        pagination={toPagination(companyList.data?.pagination)}
        loading={companyList.isLoading}
      />

      <CompanyModalForm
        opened={modalOpened}
        onClose={modalAction.close}
        company={CompanySelected}
        key={CompanySelected?.id}
      />
    </Card>
  );
};

export default CompanyList;

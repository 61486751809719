import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import sectorsServices from './sectors.services';
import { SectorListParams } from './sectors.types';

export const SECTOR_QUERY_ID = 'sectors';

export function useSectorList(params?: SectorListParams) {
  return useQuery(
    [SECTOR_QUERY_ID, params],
    () => sectorsServices.list(params),
    {
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Listagem de setores',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

export function useSectorCreate() {
  const queryClient = useQueryClient();

  return useMutation(sectorsServices.create, {
    onSuccess(data) {
      queryClient.invalidateQueries([SECTOR_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Setor cadastrado',
        message: `O setor "${data.name}" foi cadastrado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação do setor',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useSectorEdit() {
  const queryClient = useQueryClient();

  return useMutation(sectorsServices.edit, {
    onSuccess(data) {
      queryClient.invalidateQueries([SECTOR_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Setor editado',
        message: `O setor "${data.name}" foi editado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição do setor',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useSectorRemove() {
  const queryClient = useQueryClient();

  return useMutation(sectorsServices.remove, {
    onSuccess() {
      queryClient.invalidateQueries([SECTOR_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Setor removido',
        message: `O setor foi removido com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Remoção do setor',
        message: getErrorMessage(error),
      });
    },
  });
}

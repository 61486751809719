import axiosInstance from '@/core/configs/axios';
import {
  RecoveryPasswordSendMail,
  RecoveryPasswordChange,
  RecoveryPasswordChangeResponse,
} from './recoveryPassword.types';

const URL_CONTROLER = `/users`;

export default {
  async sendMail(data?: RecoveryPasswordSendMail) {
    const result = await axiosInstance.post<void>(
      `${URL_CONTROLER}/requestResetPassword`,
      data,
    );

    return result.data;
  },

  async changePassword(data?: RecoveryPasswordChange) {
    const result = await axiosInstance.post<RecoveryPasswordChangeResponse>(
      `${URL_CONTROLER}/updateForgotPassword`,
      data,
    );

    return result.data;
  },

  async validateCode(code: string) {
    const result = await axiosInstance.get<void>(
      `${URL_CONTROLER}/validateResetPasswordCode`,
      {
        params: { code },
      },
    );

    return result.data;
  },
};

import { useRecoveryPasswordChange } from '@/core/domains/recoveryPassword/recoveryPassword.hooks';
import { RecoveryPasswordFormValues } from '@/core/domains/recoveryPassword/recoveryPassword.types';

import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import {
  Stack,
  PasswordInput,
  Button,
  Group,
  Text,
  Box,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import { recoveryPasswordValidate } from './formValidate';

type Props = { code?: string };

const RecoveryPasswordForm: React.FC<Props> = ({ code }) => {
  const form = useForm<RecoveryPasswordFormValues>({
    initialValues: { newPassword: '', repeatPassword: '' },
    validate: recoveryPasswordValidate,
  });
  const [hasSuccess, setHasSuccess] = useState(false);
  const { colors } = useMantineTheme();

  const recoverPasswordChange = useRecoveryPasswordChange();

  async function handleSubmit({ newPassword }: RecoveryPasswordFormValues) {
    try {
      if (code) {
        await recoverPasswordChange.mutateAsync({ newPassword, code });
        setHasSuccess(true);
      }
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {hasSuccess && (
        <Stack align="center">
          <Group>
            <RiCheckboxCircleLine size={42} color={colors.green[6]} />

            <Box>
              <Text weight="bold">Senha alterada com sucesso</Text>
              <Text color="dimmed">
                Volte para tela de login para utilizar a nova senha
              </Text>
            </Box>
          </Group>

          <Button component="a" href="/login">
            Acessar
          </Button>
        </Stack>
      )}

      {!hasSuccess && (
        <Stack>
          <PasswordInput
            withAsterisk
            label="Nova senha"
            {...form.getInputProps('newPassword')}
          />

          <PasswordInput
            withAsterisk
            label="Repetir senha"
            {...form.getInputProps('repeatPassword')}
          />

          <Button type="submit" loading={recoverPasswordChange.isLoading}>
            Alterar senha
          </Button>
        </Stack>
      )}
    </form>
  );
};

export default RecoveryPasswordForm;

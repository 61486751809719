/* eslint-disable @typescript-eslint/no-explicit-any */

function lower(text: string) {
  return text.toLowerCase();
}

export default function getFieldPath(errorKey: string, formValues: any) {
  if (errorKey.includes('.')) {
    const fieldPaths = errorKey.split('.');

    const partsMap = fieldPaths
      .map((part) => {
        if (part.includes('[') && part.includes(']')) {
          return part
            .split('[')
            .map((supPart) =>
              supPart.includes(']') ? supPart.split(']') : supPart,
            )
            .flat();
        }

        return part;
      })
      .flat();

    const fields = partsMap
      .filter((part) => !!part)
      .map((part) => (String(Number(part)) !== 'NaN' ? Number(part) : part));

    return fields.reduce((fields, curField) => {
      if (!fields.length && typeof curField === 'string') {
        const fieldKey = Object.keys(formValues).find(
          (key) => lower(key) === lower(curField),
        );

        if (fieldKey) return [fieldKey];
      }

      if (!!fields.length && typeof curField === 'string') {
        const formField = fields.reduce(
          (prev, curr) => (prev ? prev[curr] : formValues[curr]),
          null,
        );

        if (
          !!formField &&
          Object.keys(formField).some((key) => lower(key) === lower(curField))
        ) {
          return [
            ...fields,
            Object.keys(formField).find(
              (key) => lower(key) === lower(curField),
            ) as string,
          ];
        }
      }

      if (typeof curField === 'number') {
        return [...fields, curField];
      }

      return fields;
    }, [] as (string | number)[]);
  }

  if (errorKey) {
    const formField = Object.keys(formValues).find(
      (key) => lower(key) === lower(errorKey),
    );

    if (formField) return [formField];
  }

  return [];
}

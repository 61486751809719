import { Company } from '@/core/domains/companies/companies.types';
import { Modal, Title } from '@mantine/core';
import CompanyForm from '../CompanyForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  company?: Company;
};

const CategoryModalForm: React.FC<Props> = ({ opened, onClose, company }) => {
  const title = (
    <Title order={3}>{`${
      company ? 'Edição de' : 'Cadastro de'
    } estabeleciamento`}</Title>
  );

  return (
    <Modal opened={opened} onClose={onClose} title={title} size="lg">
      <CompanyForm onCancel={onClose} company={company} />
    </Modal>
  );
};

export default CategoryModalForm;

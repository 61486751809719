import ProfileModalForm from '@/components/Profile/ProfileModalForm';
import { useAuth } from '@/core/contexts/auth';
import {
  Header,
  Group,
  ActionIcon,
  Button,
  useMantineColorScheme,
  UnstyledButton,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RiSunLine, RiMoonLine, RiLogoutBoxRLine } from 'react-icons/ri';

const PrivateHeader: React.FC = () => {
  const { setSession, session } = useAuth();
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();
  const [profileOpened, profileAction] = useDisclosure(false);

  return (
    <>
      <Header height={60} p="xs">
        <Group position="apart">
          <Text size="xl" color="primary" sx={{ letterSpacing: '0.4rem' }}>
            FIDUCAS
          </Text>

          <Group>
            <UnstyledButton onClick={profileAction.open}>
              <Text weight="bold">{session?.user.name}</Text>
            </UnstyledButton>

            <ActionIcon onClick={() => toggleColorScheme()}>
              {colorScheme === 'dark' ? <RiSunLine /> : <RiMoonLine />}
            </ActionIcon>

            <Button
              variant="default"
              onClick={() => setSession(undefined)}
              leftIcon={<RiLogoutBoxRLine />}
            >
              Sair
            </Button>
          </Group>
        </Group>
      </Header>

      <ProfileModalForm opened={profileOpened} onClose={profileAction.close} />
    </>
  );
};

export default PrivateHeader;

import { useSectorList } from '@/core/domains/sectors/sectors.hooks';
import {
  Avatar,
  Group,
  MultiSelect,
  MultiSelectProps,
  Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import { RiImageLine } from 'react-icons/ri';

const SectorMultiSelect: React.FC<MultiSelectProps> = ({
  label = 'Setores',
  placeholder = 'Selecione os setores',
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebouncedValue(search, 500);

  const sectorList = useSectorList({ search: searchDebounced });

  return (
    <MultiSelect
      {...props}
      label={label}
      placeholder={placeholder}
      data={
        sectorList.data?.items.map((item) => ({
          value: item.id,
          label: item.name,
          icon: item.icon,
        })) || []
      }
      searchable
      onSearchChange={setSearch}
      itemComponent={SelectItem}
    />
  );
};

type ItemProps = ComponentPropsWithoutRef<'div'> & {
  icon: string;
  label: string;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={icon}>
          <RiImageLine size={20} color="gray" />
        </Avatar>

        <Text>{label}</Text>
      </Group>
    </div>
  ),
);

export default SectorMultiSelect;

import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import categoriesServices from './categories.services';
import { CategoryListParams } from './categories.types';

export const CATEGORY_QUERY_ID = 'categories';

export function useCategoryList(params?: CategoryListParams) {
  return useQuery(
    [CATEGORY_QUERY_ID, params],
    () => categoriesServices.list(params),
    {
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Listagem de categorias',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

export function useCategoryCreate() {
  const queryClient = useQueryClient();

  return useMutation(categoriesServices.create, {
    onSuccess(data) {
      queryClient.invalidateQueries([CATEGORY_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Categoria cadastrada',
        message: `A categoria "${data.name}" foi cadastrada com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação da categoria',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useCategoryEdit() {
  const queryClient = useQueryClient();

  return useMutation(categoriesServices.edit, {
    onSuccess(data) {
      queryClient.invalidateQueries([CATEGORY_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Categoria editada',
        message: `A categoria "${data.name}" foi editada com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição da categoria',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useCategoryRemove() {
  const queryClient = useQueryClient();

  return useMutation(categoriesServices.remove, {
    onSuccess() {
      queryClient.invalidateQueries([CATEGORY_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Categoria removida',
        message: `A categoria foi removida com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Remoção da categoria',
        message: getErrorMessage(error),
      });
    },
  });
}

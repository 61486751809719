import { Table } from '@/components/_commons';
import {
  useSectorList,
  useSectorRemove,
} from '@/core/domains/sectors/sectors.hooks';
import { Sector, SectorListParams } from '@/core/domains/sectors/sectors.types';
import { toPagination } from '@/core/utils/functions/pagination';
import { ActionIcon, Card, Group, Image, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { RiDeleteBin2Line, RiEdit2Line } from 'react-icons/ri';
import SectorModalForm from '../SectorModalForm';

const SectorList: React.FC = () => {
  const modals = useModals();
  const filterForm = useForm<SectorListParams>();
  const [params, setParams] = useDebouncedState<SectorListParams>({}, 500);
  const [sectorSelected, setSectorSelected] = useState<Sector>();
  const [modalOpened, modalAction] = useDisclosure(false, {
    onClose: () => setSectorSelected(undefined),
  });

  const sectorList = useSectorList(params);
  const sectorRemove = useSectorRemove();

  useEffect(() => setParams(filterForm.values), [filterForm.values]);

  function handleRemove(sector: Sector) {
    modals.openConfirmModal({
      title: <Text weight="bold">Remover usuário</Text>,
      children: `Tem certeza que deseja remover o setor "${sector.name}"?`,
      labels: {
        cancel: 'Cancelar',
        confirm: 'Remover',
      },
      closeOnConfirm: false,
      confirmProps: {
        color: 'red',
        loading: sectorRemove.isLoading,
        onClick: async () => {
          await sectorRemove.mutateAsync(sector.id);
          closeAllModals();
        },
      },
    });
  }

  function handleEdit(sector: Sector) {
    setSectorSelected(sector);
    modalAction.open();
  }

  return (
    <Card withBorder>
      <Group>
        <TextInput
          placeholder="Pesquisar setor"
          mb="xl"
          {...filterForm.getInputProps('search')}
        />
      </Group>

      <Table
        columns={[
          {
            title: 'Setor',
            key: 'name',

            render: (sector) => (
              <Group>
                {<Image width={40} src={sector.icon} withPlaceholder />}

                <Text>{sector.name}</Text>
              </Group>
            ),
          },

          {
            title: 'Ação',
            align: 'right',
            render: (item) => (
              <Group position="right">
                <ActionIcon title="Editar" onClick={() => handleEdit(item)}>
                  <RiEdit2Line />
                </ActionIcon>

                <ActionIcon
                  title="Remover"
                  color="red.5"
                  onClick={() => handleRemove(item)}
                >
                  <RiDeleteBin2Line />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        data={sectorList.data?.items}
        pagination={toPagination(sectorList.data?.pagination)}
        loading={sectorList.isLoading}
      />

      <SectorModalForm
        opened={modalOpened}
        onClose={modalAction.close}
        sector={sectorSelected}
        key={sectorSelected?.id}
      />
    </Card>
  );
};

export default SectorList;

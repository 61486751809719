import axiosInstance from '@/core/configs/axios';
import { useAuth } from '@/core/contexts/auth';
import { useAuthLogin } from '@/core/domains/auth/auth.hooks';
import { AuthDecoded, AuthRoleEnum } from '@/core/domains/auth/auth.types';
import { useUserInfo } from '@/core/domains/users/users.hooks';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { Button, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import jwtDecode from 'jwt-decode';
import { RiLoginBoxLine } from 'react-icons/ri';
import { loginValidate } from './formValidate';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { setSession } = useAuth();

  const form = useForm<LoginFormValues>({
    initialValues: { email: '', password: '' },
    validate: loginValidate,
  });

  const authLogin = useAuthLogin();
  const userDetailInfo = useUserInfo();

  async function handleSubmit(values: LoginFormValues) {
    try {
      const result = await authLogin.mutateAsync(values);

      const authInfo = jwtDecode<AuthDecoded>(result.token);

      if (!authInfo || authInfo.role !== AuthRoleEnum.Admin) {
        showNotification({
          color: 'yellow',
          title: 'Acesso negado',
          message: 'O usuário informado não tem acesso administrativo',
        });

        return;
      }

      const userInfo = await userDetailInfo.mutateAsync(result.token);

      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${result.token}`;

      setSession({
        user: {
          id: userInfo.id,
          name: `${userInfo.firstName} ${userInfo.lastName}`,
          email: userInfo.email,
        },
        tokens: {
          accessToken: result.token,
          refreshToken: result.token,
          tokenType: 'Bearer',
          expirateAt: authInfo.exp,
          idToken: null,
        },
      });
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack sx={{ minWidth: 240 }}>
        <TextInput
          withAsterisk
          name="email"
          label="E-mail"
          {...form.getInputProps('email')}
        />

        <PasswordInput
          withAsterisk
          name="password"
          label="Senha"
          {...form.getInputProps('password')}
        />

        <Button
          leftIcon={<RiLoginBoxLine />}
          type="submit"
          loading={authLogin.isLoading || userDetailInfo.isLoading}
        >
          Acessar
        </Button>
      </Stack>
    </form>
  );
};

export default LoginForm;

import { RouteProps } from 'react-router-context';
import {
  RiBarcodeBoxLine,
  RiBuilding3Line,
  RiBuildingLine,
  RiGroupLine,
  RiPriceTag3Line,
  RiQrCodeLine,
} from 'react-icons/ri';
import { RouteParams } from '../types';

// Pages
import UsersPage from '@/pages/Private/Users';
import CategoriesPage from '@/pages/Private/Categories';
import SectorsPage from '@/pages/Private/Sectors';
import CompaniesPage from '@/pages/Private/Comapanies';
import BoxesPage from '@/pages/Private/Boxes';
import ProductsPage from '@/pages/Private/Products';
import BoxDetailPage from '@/pages/Private/Boxes/BoxesDetail';

export default [
  {
    index: true,
    element: <UsersPage />,
    params: { hidden: true },
  },
  {
    path: 'users',
    element: <UsersPage />,
    params: { title: 'Usuários', icon: <RiGroupLine /> },
  },
  {
    path: 'categories',
    element: <CategoriesPage />,
    params: { title: 'Categorias', icon: <RiPriceTag3Line /> },
  },
  {
    path: 'sectors',
    element: <SectorsPage />,
    params: { title: 'Setores', icon: <RiBuilding3Line /> },
  },
  {
    path: 'companies',
    element: <CompaniesPage />,
    params: { title: 'Estabelecimento', icon: <RiBuildingLine /> },
  },
  {
    path: 'boxes',
    element: <BoxesPage />,
    params: { title: 'Armários', icon: <RiQrCodeLine /> },
  },
  {
    path: 'boxes/:boxIdentifier',
    element: <BoxDetailPage />,
    params: {
      title: 'Armário',
      icon: <RiBuildingLine />,
      hidden: true,
    },
  },
  {
    path: 'products',
    element: <ProductsPage />,
    params: { title: 'Produtos', icon: <RiBarcodeBoxLine /> },
  },
] as RouteProps<RouteParams>[];

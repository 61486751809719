import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  Sector,
  SectorCreateRequest,
  SectorEditRequest,
  SectorListParams,
  SectorListResponse,
} from './sectors.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/sectors`;

export default {
  async list(params?: SectorListParams) {
    const result = await axiosInstance.get<SectorListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: SectorCreateRequest) {
    const result = await axiosInstance.post<Sector>(`${URL_CONTROLER}`, data);

    return result.data;
  },

  async edit(data: SectorEditRequest) {
    const result = await axiosInstance.put<Sector>(
      `${URL_CONTROLER}/${data.id}`,
      data,
    );

    return result.data;
  },

  async detail(sectorId: string) {
    const result = await axiosInstance.get<Sector>(
      `${URL_CONTROLER}/${sectorId}`,
    );

    return result.data;
  },

  async remove(sectorId: string) {
    const result = await axiosInstance.delete<void>(
      `${URL_CONTROLER}/${sectorId}`,
    );

    return result.data;
  },
};

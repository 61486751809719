import { Table } from '@/components/_commons';
import { useInventoryControlList } from '@/core/domains/inventoryControls/inventoryControls.hooks';
import {
  InventoryControlType,
  InventoryControlTypes,
} from '@/core/domains/inventoryControls/inventoryControls.types';
import { toPagination } from '@/core/utils/functions/pagination';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Group,
  Skeleton,
  Text,
  Title,
} from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import InventoryControlModalForm from '../InventoryControlModalForm';
import TypeSelect from '../TypeSelect';

type Props = {
  boxIdentifier?: string;
};

type Filter = {
  dates: [Date | null, Date | null];
  isExpired: boolean;
  type: string | null;
};

const InventoryControlList: React.FC<Props> = ({ boxIdentifier }) => {
  const [filter, setFilter] = useState<Filter>({
    dates: [null, null],
    isExpired: false,
    type: null,
  });
  const { data, isLoading } = useInventoryControlList({
    boxIdentifier,
    startDate: filter.dates[0] ? filter.dates[0].toDateString() : undefined,
    endDate: filter.dates[1] ? filter.dates[1].toDateString() : undefined,
    isExpired: filter.isExpired,
    type: filter.type
      ? (Number(filter.type) as InventoryControlType)
      : undefined,
  });
  const [controlOpened, controlAction] = useDisclosure(false);

  const typeColor = ['green', 'red', 'gray'];

  function handleDates(dates: DateRangePickerValue) {
    if (dates.length === 2) {
      if (dates[0] && dates[1]) {
        setFilter((state) => ({ ...state, dates }));
      }
      if (!dates[0] && !dates[1]) {
        setFilter((state) => ({ ...state, dates }));
      }
    }
  }

  return (
    <>
      <Card>
        <Group mb="xl" position="apart">
          <Title order={3}>Controle de inventário</Title>

          <Button leftIcon={<RiAddLine />} onClick={controlAction.open}>
            Adicionar controle
          </Button>
        </Group>

        <Text weight="bold" mb="md">
          Filtro
        </Text>

        <Group mb="xl">
          <DateRangePicker
            value={filter.dates}
            placeholder="00/00/0000 - 00/00/0000"
            sx={{ width: 220 }}
            onChange={handleDates}
            inputFormat="DD/MM/YYYY"
            dropdownPosition="top-start"
          />

          <TypeSelect
            label={false}
            value={String(filter?.type)}
            onChange={(type) => setFilter((state) => ({ ...state, type }))}
          />

          <Checkbox
            checked={filter.isExpired}
            onChange={({ currentTarget }) =>
              setFilter((state) => ({
                ...state,
                isExpired: currentTarget.checked,
              }))
            }
            label="Produtos expirados"
          />
        </Group>

        <Skeleton visible={isLoading}>
          <Table
            data={data?.items || []}
            columns={[
              {
                title: 'Produto',
                key: 'product',
                render: (item) => item.product.name,
              },
              { title: 'Quantidade', key: 'quantity' },
              {
                title: 'Tipo',
                key: 'type',
                render: (item) => (
                  <Badge color={typeColor[item.type]}>
                    {InventoryControlTypes[item.type]}
                  </Badge>
                ),
              },
            ]}
            pagination={toPagination(data?.pagination)}
          />
        </Skeleton>
      </Card>

      <InventoryControlModalForm
        opened={controlOpened}
        onClose={controlAction.close}
        boxIdentifier={boxIdentifier}
      />
    </>
  );
};

export default InventoryControlList;

import { Modal, Title } from '@mantine/core';
import ProfileForm from '../ProfileForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
};

const ProfileModalForm: React.FC<Props> = ({ opened, onClose }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Edição do usuário</Title>}
    >
      <ProfileForm onCancel={onClose} />
    </Modal>
  );
};

export default ProfileModalForm;

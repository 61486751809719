import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  Box,
  BoxEditRequest,
  BoxFormValues,
  BoxListParams,
  BoxListResponse,
} from './boxes.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/boxes`;

export default {
  async list(params?: BoxListParams) {
    const result = await axiosInstance.get<BoxListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: BoxFormValues) {
    const result = await axiosInstance.post<Box>(`${URL_CONTROLER}`, data);

    return result.data;
  },

  async edit({ identifier, ...data }: BoxEditRequest) {
    const result = await axiosInstance.put<Box>(
      `${URL_CONTROLER}/${identifier}`,
      data,
    );

    return result.data;
  },

  async detail(boxIdentifier: string) {
    const result = await axiosInstance.get<Box>(
      `${URL_CONTROLER}/${boxIdentifier}`,
    );

    return result.data;
  },

  async remove(boxIdentifier: string) {
    const result = await axiosInstance.delete<void>(
      `${URL_CONTROLER}/${boxIdentifier}`,
    );

    return result.data;
  },
};

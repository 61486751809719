import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import viaCepServices from './viaCep.services';

export function useViaCep() {
  return useMutation(viaCepServices.addressByCep, {
    onError() {
      showNotification({
        color: 'red',
        title: 'Busca do endereço',
        message: 'Verifique o cep informado',
      });
    },
  });
}

import { Table } from '@/components/_commons';
import {
  useProductList,
  useProductRemove,
} from '@/core/domains/products/products.hooks';
import {
  Product,
  ProductListParams,
} from '@/core/domains/products/products.types';
import { toPagination } from '@/core/utils/functions/pagination';
import {
  ActionIcon,
  Avatar,
  Card,
  Group,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { RiDeleteBin2Line, RiEditLine, RiImageLine } from 'react-icons/ri';
import ProductModalForm from '../ProductModalForm';

const ProductList: React.FC = () => {
  const modals = useModals();
  const filterForm = useForm<ProductListParams>();
  const [params, setParams] = useDebouncedState<ProductListParams>({}, 500);
  const [productSelected, setProductSelected] = useState<Product>();
  const [modalOpened, modalAction] = useDisclosure(false, {
    onClose: () => setProductSelected(undefined),
  });

  const productList = useProductList(params);
  const productRemove = useProductRemove();

  useEffect(() => setParams(filterForm.values), [filterForm.values]);

  function handleRemove(product: Product) {
    modals.openConfirmModal({
      title: <Text weight="bold">Remover usuário</Text>,
      children: `Tem certeza que deseja remover o produto "${product.name}"?`,
      labels: {
        cancel: 'Cancelar',
        confirm: 'Remover',
      },
      closeOnConfirm: false,
      confirmProps: {
        color: 'red',
        loading: productRemove.isLoading,
        onClick: async () => {
          await productRemove.mutateAsync(product.id);
          closeAllModals();
        },
      },
    });
  }

  function handleEdit(product: Product) {
    setProductSelected(product);
    modalAction.open();
  }

  return (
    <Card withBorder>
      <Group>
        <TextInput
          placeholder="Pesquisar produtos"
          mb="xl"
          {...filterForm.getInputProps('search')}
        />
      </Group>

      <Table
        columns={[
          {
            title: 'Produto',
            key: 'name',
            render: (item) => (
              <Group>
                <Avatar src={item.imageUris[0]}>
                  <RiImageLine size={24} />
                </Avatar>

                <Text>{item.name}</Text>
              </Group>
            ),
          },

          {
            title: 'Categoria',
            key: 'category',
            render: (item) => item.category.name,
          },

          {
            title: 'Quantidade',
            key: 'quantity',
            align: 'right',
          },

          {
            title: 'Ação',
            align: 'right',
            render: (item) => (
              <Group position="right">
                <ActionIcon title="Editar" onClick={() => handleEdit(item)}>
                  <RiEditLine />
                </ActionIcon>

                <ActionIcon
                  title="Remover"
                  color="red.5"
                  onClick={() => handleRemove(item)}
                >
                  <RiDeleteBin2Line />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        data={productList.data?.items}
        pagination={toPagination(productList.data?.pagination)}
        loading={productList.isLoading}
      />

      <ProductModalForm
        opened={modalOpened}
        onClose={modalAction.close}
        product={productSelected}
        key={productSelected?.id}
      />
    </Card>
  );
};

export default ProductList;

import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const companyAddressValidate = Yup.object({
  streetName: Yup.string().required(FORM_VALIDATE.required),
  streetNumber: Yup.string().required(FORM_VALIDATE.required),
  complement: Yup.string(),
  uf: Yup.string().required(FORM_VALIDATE.required),
  city: Yup.string().required(FORM_VALIDATE.required),
  cep: Yup.string().required(FORM_VALIDATE.required),
});

export const companyFormValidate = yupResolver(
  Yup.object().shape({
    name: Yup.string().required(FORM_VALIDATE.required),
    address: companyAddressValidate.required(FORM_VALIDATE.required),
    cnpj: Yup.string().required(FORM_VALIDATE.required),
    businnessHours: Yup.string().required(FORM_VALIDATE.required),
    contact: Yup.string().required(FORM_VALIDATE.required),
  }),
);

import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const productFormValidate = yupResolver(
  Yup.object().shape({
    name: Yup.string().required(FORM_VALIDATE.required),
    description: Yup.string().required(FORM_VALIDATE.required),
    barCode: Yup.string().required(FORM_VALIDATE.required),
    price: Yup.number()
      .min(0, FORM_VALIDATE.minCount)
      .required(FORM_VALIDATE.required),
    categoryId: Yup.string().nullable().required(FORM_VALIDATE.required),
    imageUris: Yup.array()
      .of(Yup.string())
      .min(1, FORM_VALIDATE.minCount)
      .required(FORM_VALIDATE.required),
  }),
);

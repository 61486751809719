import { Table } from '@/components/_commons';
import {
  useCategoryList,
  useCategoryRemove,
} from '@/core/domains/categories/categories.hooks';
import {
  Category,
  CategoryListParams,
} from '@/core/domains/categories/categories.types';
import { toPagination } from '@/core/utils/functions/pagination';
import { ActionIcon, Card, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { RiDeleteBin2Line, RiEdit2Line } from 'react-icons/ri';
import CategoryModalForm from '../CategoryModalForm';

const CatogoryList: React.FC = () => {
  const modals = useModals();
  const filterForm = useForm<CategoryListParams>();
  const [params, setParams] = useDebouncedState<CategoryListParams>({}, 500);
  const [categorySelected, setCategorySelected] = useState<Category>();
  const [modalOpened, modalAction] = useDisclosure(false, {
    onClose: () => setCategorySelected(undefined),
  });

  const categoryList = useCategoryList(params);
  const categoryRemove = useCategoryRemove();

  useEffect(() => setParams(filterForm.values), [filterForm.values]);

  function handleRemove(category: Category) {
    modals.openConfirmModal({
      title: <Text weight="bold">Remover usuário</Text>,
      children: `Tem certeza que deseja remover a categoria "${category.name}"?`,
      labels: {
        cancel: 'Cancelar',
        confirm: 'Remover',
      },
      closeOnConfirm: false,
      confirmProps: {
        color: 'red',
        loading: categoryRemove.isLoading,
        onClick: async () => {
          await categoryRemove.mutateAsync(category.id);
          closeAllModals();
        },
      },
    });
  }

  function handleEdit(category: Category) {
    setCategorySelected(category);
    modalAction.open();
  }

  return (
    <Card withBorder>
      <Group>
        <TextInput
          placeholder="Pesquisar categorias"
          mb="xl"
          {...filterForm.getInputProps('search')}
        />
      </Group>

      <Table
        columns={[
          {
            title: 'Categoria',
            key: 'name',
          },

          {
            title: 'Ação',
            align: 'right',
            render: (item) => (
              <Group position="right">
                <ActionIcon title="Editar" onClick={() => handleEdit(item)}>
                  <RiEdit2Line />
                </ActionIcon>

                <ActionIcon
                  title="Remover"
                  color="red.5"
                  onClick={() => handleRemove(item)}
                >
                  <RiDeleteBin2Line />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        data={categoryList.data?.items}
        pagination={toPagination(categoryList.data?.pagination)}
        loading={categoryList.isLoading}
      />

      <CategoryModalForm
        opened={modalOpened}
        onClose={modalAction.close}
        category={categorySelected}
        key={categorySelected?.id}
      />
    </Card>
  );
};

export default CatogoryList;

import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const sectorFormValidate = yupResolver(
  Yup.object().shape({
    name: Yup.string().required(FORM_VALIDATE.required),
    icon: Yup.string().required(FORM_VALIDATE.required),
  }),
);

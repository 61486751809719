import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  User,
  UserEditRequest,
  UserFormValues,
  UserListParams,
  UserListResponse,
} from './users.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/users`;

export default {
  async list(params?: UserListParams) {
    const result = await axiosInstance.get<UserListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: UserFormValues) {
    const result = await axiosInstance.post<User>(`${URL_CONTROLER}`, data);

    return result.data;
  },

  async edit(data: UserEditRequest) {
    const result = await axiosInstance.put<User>(
      `${URL_CONTROLER}/${data.id}`,
      data,
    );

    return result.data;
  },

  async detail(userId: string) {
    const result = await axiosInstance.get<User>(`${URL_CONTROLER}/${userId}`);

    return result.data;
  },

  async me() {
    const result = await axiosInstance.get<User>(`${URL_CONTROLER}/me`);

    return result.data;
  },

  async detailInfo(token: string) {
    const result = await axiosInstance.get<User>(`${URL_CONTROLER}/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return result.data;
  },

  async remove(userId: string) {
    const result = await axiosInstance.delete<void>(
      `${URL_CONTROLER}/${userId}`,
    );

    return result.data;
  },
};

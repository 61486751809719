import { SectorList } from '@/components/Sectors';
import SectorModalForm from '@/components/Sectors/SectorModalForm';
import { Button, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RiAddLine } from 'react-icons/ri';

const SectorsPage: React.FC = () => {
  const [modalOpened, modalAction] = useDisclosure(false);

  return (
    <>
      <Group mb="xl" position="apart">
        <Title order={2}>Setores</Title>

        <Button onClick={modalAction.open} leftIcon={<RiAddLine />}>
          Cadastrar setor
        </Button>
      </Group>

      <SectorList />

      <SectorModalForm opened={modalOpened} onClose={modalAction.close} />
    </>
  );
};

export default SectorsPage;

import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import { FileCreateRequest, FileCreateResponse } from './files.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/files`;

export default {
  async upload(files: FileCreateRequest) {
    const formData = new FormData();

    files.forEach((file, _i) => {
      formData.append(`files`, file);
    });

    const result = await axiosInstance.post<FileCreateResponse>(
      `${URL_CONTROLER}`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      },
    );

    return result.data;
  },
};

import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  Company,
  CompanyEditRequest,
  CompanyFormValues,
  CompanyListParams,
  CompanyListResponse,
} from './companies.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/companies`;

export default {
  async list(params?: CompanyListParams) {
    const result = await axiosInstance.get<CompanyListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: CompanyFormValues) {
    const result = await axiosInstance.post<Company>(`${URL_CONTROLER}`, data);

    return result.data;
  },

  async edit(data: CompanyEditRequest) {
    const result = await axiosInstance.put<Company>(
      `${URL_CONTROLER}/${data.id}`,
      data,
    );

    return result.data;
  },

  async detail(companyId: string) {
    const result = await axiosInstance.get<Company>(
      `${URL_CONTROLER}/${companyId}`,
    );

    return result.data;
  },

  async remove(companyId: string) {
    const result = await axiosInstance.delete<void>(
      `${URL_CONTROLER}/${companyId}`,
    );

    return result.data;
  },
};

import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  InventoryControl,
  InventoryControlCreate,
  InventoryControlListParams,
  InventoryControlListResponse,
} from './inventoryControls.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/inventoryControls`;

export default {
  async list(params?: InventoryControlListParams) {
    const result = await axiosInstance.get<InventoryControlListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: InventoryControlCreate) {
    const result = await axiosInstance.post<InventoryControl>(
      `${URL_CONTROLER}`,
      data,
    );

    return result.data;
  },
};

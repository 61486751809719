import { BoxList, BoxModalForm } from '@/components/Boxes';
import { Button, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RiAddLine } from 'react-icons/ri';

const BoxesPage: React.FC = () => {
  const [modalOpened, modalAction] = useDisclosure(false);

  return (
    <>
      <Group mb="xl" position="apart">
        <Title order={2}>Armários</Title>

        <Button onClick={modalAction.open} leftIcon={<RiAddLine />}>
          Cadastrar armário
        </Button>
      </Group>

      <BoxList />

      <BoxModalForm opened={modalOpened} onClose={modalAction.close} />
    </>
  );
};

export default BoxesPage;

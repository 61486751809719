import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import productsServices from './products.services';
import { ProductListParams } from './products.types';

export const PRODUCT_QUERY_ID = 'products';

export function useProductList(params?: ProductListParams) {
  return useQuery(
    [PRODUCT_QUERY_ID, params],
    () => productsServices.list(params),
    {
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Listagem de produtos',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

export function useProductCreate() {
  const queryClient = useQueryClient();

  return useMutation(productsServices.create, {
    onSuccess(data) {
      queryClient.invalidateQueries([PRODUCT_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Produto cadastrado',
        message: `O produto "${data.name}" foi cadastrado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação do produto',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useProductEdit() {
  const queryClient = useQueryClient();

  return useMutation(productsServices.edit, {
    onSuccess(data) {
      queryClient.invalidateQueries([PRODUCT_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Produto editado',
        message: `O produto "${data.name}" foi editado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição do produto',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useProductRemove() {
  const queryClient = useQueryClient();

  return useMutation(productsServices.remove, {
    onSuccess() {
      queryClient.invalidateQueries([PRODUCT_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Produto removido',
        message: `O produto foi removido com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Remoção do produto',
        message: getErrorMessage(error),
      });
    },
  });
}

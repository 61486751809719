import { AxiosErrorResponse } from '@/core/types/services';
import getErrorMessage from '@/core/utils/functions/getErrorMessage';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import companiesServices from './companies.services';
import { CompanyListParams } from './companies.types';

export const COMPANY_QUERY_ID = 'categories';

export function useCompanyList(params?: CompanyListParams) {
  return useQuery(
    [COMPANY_QUERY_ID, params],
    () => companiesServices.list(params),
    {
      onError(error: AxiosErrorResponse) {
        showNotification({
          color: 'red',
          title: 'Listagem de estabelecimentos',
          message: getErrorMessage(error),
        });
      },
    },
  );
}

export function useCompanyCreate() {
  const queryClient = useQueryClient();

  return useMutation(companiesServices.create, {
    onSuccess(data) {
      queryClient.invalidateQueries([COMPANY_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Estapelecimento cadastrado',
        message: `O estabelecimento "${data.name}" foi cadastrado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Criação do estabelecimento',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useCompanyEdit() {
  const queryClient = useQueryClient();

  return useMutation(companiesServices.edit, {
    onSuccess(data) {
      queryClient.invalidateQueries([COMPANY_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Estapelecimento editado',
        message: `O estabelecimento "${data.name}" foi editado com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Edição do estabelecimento',
        message: getErrorMessage(error),
      });
    },
  });
}

export function useCompanyRemove() {
  const queryClient = useQueryClient();

  return useMutation(companiesServices.remove, {
    onSuccess() {
      queryClient.invalidateQueries([COMPANY_QUERY_ID]);

      showNotification({
        color: 'green',
        title: 'Estapelecimento removido',
        message: `O estabelecimento foi removido com sucesso!`,
      });
    },
    onError(error: AxiosErrorResponse) {
      showNotification({
        color: 'red',
        title: 'Remoção do estabelecimento',
        message: getErrorMessage(error),
      });
    },
  });
}

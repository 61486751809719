import { Sector } from '@/core/domains/sectors/sectors.types';
import { Modal, Title } from '@mantine/core';
import CategoryForm from '../SectorForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  sector?: Sector;
};

const CategoryModalForm: React.FC<Props> = ({ opened, onClose, sector }) => {
  const title = (
    <Title order={3}>{`${sector ? 'Edição de' : 'Cadastro de'} setor`}</Title>
  );

  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      <CategoryForm onCancel={onClose} sector={sector} />
    </Modal>
  );
};

export default CategoryModalForm;

import { Box, Container, Center, Image, Text, Title } from '@mantine/core';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Container>
        <Center sx={{ height: 80 }} mb="xl">
          <Image src="/images/fiducas-logo.png" width={80} />
        </Center>

        <Box>
          <Title mb="xl">Política de Privacidade</Title>

          <Text mb="xl">
            A Fiducas, pessoa jurídica de direito privado com sede na rua
            Maranjaí, 281, Ipiranga, São Paulo, CEP 01549-010 inscrito no CNPJ:
            34.563.469/0001-50, criou esta política de privacidade com a
            finalidade de proteger e esclarecer o manuseio e utilização dos
            dados pessoais disponibilizados pela Fiducas. Caso não concorde com
            qualquer termo disposto nesta política de privacidade, solicitamos
            que não utilize nossos produtos ou serviços, ou realize qualquer
            operação na plataforma Fiducas. Ao aceitar os termos dessa Política
            de Privacidade, você esta ciente que a controladora dos seus dados
            pessoais será a Fiducas.
          </Text>

          <Title order={3}>1- Quais os dados pessoais coletados?</Title>

          <Text mb="xl">Dados cadastrais, nome, e-mail.</Text>

          <Title order={3}>
            2- Quais os dados originados de nossos serviços coletados?
          </Title>

          <Text mb="xl">
            Dados de contratação de produtos e serviços. Dados de histórico de
            compras. Dados de histórico de atendimento ao cliente.
          </Text>
          <Text mb="xl">
            O app Fiducas coleta, trasmite e armazena dados como: a data e hora
            de abertura dos armários IOT nos estabelecimentos parceiros, hora e
            data da compra realizada pelo app Fiducas, nome e identidade do
            armário IOT Fiducas o qual foi realizada a compra e venda dos
            produtos.
          </Text>

          <Title order={3}>
            3- Coleta, finalidade, e como Fiducas usa os dados pessoais?
          </Title>

          <Text mb="xl">
            Prestação de serviços e oferecimento de produtos e serviços.
            Autenticação de transações. Atendimentos de solicitações e dúvidas.
            Contato por telefone, e-mail, whatsapp. Se necessário,
            compartilhamento de dados com empresas fornecedoras e terceiros
            parceiros para realizações de atividades como serviços logísticos,
            análise de banco de dados, criações de ações de marketing e
            processamento de pagamentos, ou qualquer outra atividade que permita
            o compartilhamento de dados com terceiros para aperfeiçoamento das
            atividades Fiducas; sempre respeitando a LGPD (Lei Geral de Proteção
            de Dados). É possível que haja transferência de dados pessoais para
            fora do Brasil para serviços de armazenamento em nuvem. Operações
            internas: caso houver instabilidade para solução de problemas e seja
            necessário realizar testes e tratamento de dados pessoais. Estudos
            de mercado: com a finalidade de garantir a melhoria contínua de
            nossos produtos e serviços, viabilidade de novos testes e soluções
            com apenas a compreensão do perfil de nossos clientes a fim de
            melhoramento.
          </Text>

          <Title order={3}>
            4- Retenção/exclusão/correção dos dados pessoais
          </Title>
          <Text mb="xl">
            Enquanto cliente Fiducas, na utilização de nossos produtos e
            serviços durante período em que Fiducas armazenará seus dados, eles
            serão mantidos em ambiente seguro e controlado. Caso haja
            desatualização dos dados, correção, ou dados incompletos; poderá ser
            requisitado a atualização dos mesmos.
          </Text>

          <Title order={3}>5- Pagamento</Title>
          <Text mb="xl">
            Os dados referentes à questão financeira serão de inteira
            responsabilidade à empresa terceira prestadora dos serviços
            financeiros para Fiducas. Promoção de proteção ao crédito. Prevenir
            fraudes e garantir a segurança na prestação de nossos serviços e
            oferecimento de nossos produtos. Para prevenir problemas técnicos ou
            de segurança. Para proteger nossos direitos e propriedades,
            inclusive de invasões e incidentes de segurança. Para viabilizar
            transações financeiras.
          </Text>

          <Title order={3}>6- Disposições Gerais</Title>
          <Text mb="xl">
            Esta política será interpretada e executada de acordo com as Leis da
            República Federativa do Brasil, LGPD Lei n° 13.709/2018
          </Text>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicyPage;

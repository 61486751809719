import { Product } from '@/core/domains/products/products.types';
import { Modal, Title } from '@mantine/core';
import ProductForm from '../ProductForm';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  product?: Product;
};

const CategoryModalForm: React.FC<Props> = ({ opened, onClose, product }) => {
  const title = (
    <Title order={3}>{`${
      product ? 'Edição de' : 'Cadastro de'
    } produto`}</Title>
  );

  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      <ProductForm onCancel={onClose} product={product} />
    </Modal>
  );
};

export default CategoryModalForm;

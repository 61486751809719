import { AxiosErrorResponse } from '@/core/types/services';
import messageErrorTranslate from './messageErrorTranslate';

export default function getErrorMessage(error: AxiosErrorResponse) {
  if (error.isAxiosError && error.response?.data) {
    const errorData = error.response.data;

    if (errorData.description) {
      return errorData.description;
    }
    if (errorData.fieldErrors && Object.keys(errorData.fieldErrors).length) {
      return (
        <>
          {Object.keys(errorData.fieldErrors).map((errorKey) => (
            <ul
              key={errorKey}
              style={{ margin: 0, padding: 0, marginBottom: 10 }}
            >
              {errorData.fieldErrors[errorKey].map((message, index) => (
                <li key={`${errorKey}-${index}`}>
                  {messageErrorTranslate(message)}
                </li>
              ))}
            </ul>
          ))}
        </>
      );
    }
  }

  if (error.isAxiosError) {
    return error.message;
  }

  return 'Não foi possível finalizar a requisição.';
}

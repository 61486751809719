import { Select, SelectProps } from '@mantine/core';

const typeOptions = [
  { value: '', label: 'Todos' },
  { value: '0', label: 'Entrada' },
  { value: '1', label: 'Saída por compra' },
  { value: '2', label: 'Vencimento' },
  { value: '3', label: 'Retornado' },
];

type Props = Omit<SelectProps, 'data' | 'label'> & {
  isFilter?: boolean;
  label?: string | boolean;
};

const TypeSelect: React.FC<Props> = ({
  label,
  placeholder = 'Selecione o tipo',
  isFilter = true,
  ...props
}) => {
  const selectLabel =
    typeof label === 'string'
      ? label
      : label === false
      ? undefined
      : 'Selecione o tipo';
  const dataFiltered = typeOptions.filter((type) => type.value !== '1');

  return (
    <Select
      {...props}
      data={!isFilter ? dataFiltered : typeOptions}
      placeholder={placeholder}
      label={selectLabel}
    />
  );
};

export default TypeSelect;

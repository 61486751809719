import { useAuth } from '@/core/contexts/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { session, state } = useAuth();

  useEffect(() => {
    if (state !== 'LOADING' && session?.tokens?.accessToken) {
      navigate('/app');
    } else {
      navigate('/login');
    }
  }, [session, state]);

  return null;
};

export default HomePage;

import {
  useCategoryCreate,
  useCategoryEdit,
} from '@/core/domains/categories/categories.hooks';
import {
  Category,
  CategoryFormValues,
} from '@/core/domains/categories/categories.types';
import formSetFieldsErrors from '@/core/utils/functions/formSetFieldsErrors';
import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { RiSave2Line } from 'react-icons/ri';
import { categoryFormValidate } from './formValidate';

type Props = { onCancel: VoidFunction; category?: Category };

const CategoryCreateForm: React.FC<Props> = ({ onCancel, category }) => {
  const form = useForm<CategoryFormValues>({
    validate: categoryFormValidate,
  });

  const categoryCreate = useCategoryCreate();
  const categoryEdit = useCategoryEdit();

  useEffect(() => {
    if (category) {
      form.setValues({ name: category.name });
    }
  }, [category]);

  async function handleSubmit(values: CategoryFormValues) {
    try {
      if (category) {
        await categoryEdit.mutateAsync({ ...values, id: category.id });
      } else {
        await categoryCreate.mutateAsync(values);
      }
      handleCancel();
    } catch (error) {
      formSetFieldsErrors(form, error);
    }
  }

  function handleCancel() {
    onCancel();
    form.reset();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          withAsterisk
          label="Nome da categoria"
          {...form.getInputProps('name')}
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            type="submit"
            leftIcon={<RiSave2Line />}
            loading={categoryCreate.isLoading || categoryEdit.isLoading}
          >
            Salvar
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CategoryCreateForm;

import { AuthState, useAuth } from '@/core/contexts/auth';
import { AuthDecoded } from '@/core/domains/auth/auth.types';
import { AxiosError, AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { PropsWithChildren, useEffect } from 'react';

type Props = PropsWithChildren<{
  axiosClient: AxiosInstance;
}>;

const AxiosProvider: React.FC<Props> = ({ axiosClient, children }) => {
  const { session, setSession, state } = useAuth();

  useEffect(() => {
    axiosClient.interceptors.request.use(
      async (config) => {
        const authRefreshUrl = '/auth/refresh';

        if (
          state === AuthState.AUTHENTICATED &&
          session?.tokens &&
          !config.url?.includes(authRefreshUrl)
        ) {
          const dateExp = dayjs(session?.tokens.expirateAt * 1000);

          if (dateExp.subtract(1, 'day').isBefore(dayjs())) {
            try {
              const result = await axiosClient
                .post<{ token: string }>(authRefreshUrl)
                .then(({ data }) => data);

              if (result.token) {
                const dataToken = jwtDecode<AuthDecoded>(result.token);

                setSession({
                  ...session,
                  tokens: {
                    ...session.tokens,
                    accessToken: result.token,
                    refreshToken: result.token,
                    expirateAt: dataToken.exp,
                    idToken: null,
                  },
                });

                return {
                  ...config,
                  headers: {
                    ...config.headers,
                    common: {
                      Authorization: `Bearer ${result.token}`,
                    },
                  },
                };
              }
            } catch {
              return config;
            }
          }
        }

        return config;
      },
      (error: AxiosError) => error,
    );

    axiosClient.interceptors.response.use(
      (config) => config,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          setSession(null);
        }

        return Promise.reject(error);
      },
    );
  }, [axiosClient, state]);

  return <>{children}</>;
};

export default AxiosProvider;

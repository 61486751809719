import axiosInstance, { SERVICE_BASE_URL } from '@/core/configs/axios';
import {
  Category,
  CategoryEditRequest,
  CategoryFormValues,
  CategoryListParams,
  CategoryListResponse,
} from './categories.types';

const URL_CONTROLER = `${SERVICE_BASE_URL}/categories`;

export default {
  async list(params?: CategoryListParams) {
    const result = await axiosInstance.get<CategoryListResponse>(
      `${URL_CONTROLER}`,
      { params },
    );

    return result.data;
  },

  async create(data: CategoryFormValues) {
    const result = await axiosInstance.post<Category>(`${URL_CONTROLER}`, data);

    return result.data;
  },

  async edit(data: CategoryEditRequest) {
    const result = await axiosInstance.put<Category>(
      `${URL_CONTROLER}/${data.id}`,
      data,
    );

    return result.data;
  },

  async detail(categoryId: string) {
    const result = await axiosInstance.get<Category>(
      `${URL_CONTROLER}/${categoryId}`,
    );

    return result.data;
  },

  async remove(categoryId: string) {
    const result = await axiosInstance.delete<void>(
      `${URL_CONTROLER}/${categoryId}`,
    );

    return result.data;
  },
};

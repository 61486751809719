import { FORM_VALIDATE } from '@/core/utils/constants/formValitate';
import { yupResolver } from '@mantine/form';
import * as Yup from 'yup';

export const recoveryPasswordValidate = yupResolver(
  Yup.object().shape({
    newPassword: Yup.string()
      .min(6, FORM_VALIDATE.min)
      .required(FORM_VALIDATE.required),
    repeatPassword: Yup.string()
      .min(6, FORM_VALIDATE.min)
      .oneOf([Yup.ref('newPassword')], FORM_VALIDATE.passwordDiff)
      .required(FORM_VALIDATE.required),
  }),
);
